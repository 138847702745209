import { Box, Divider, MenuItem, Stack, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { alpha, styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { brandSetting } from "src/config";
import useAppTour from "src/hooks/useAppTourContext";
import useNodesContext from "src/hooks/useNodesContext";
import useSettings from "src/hooks/useSettings";
import { Iconify } from "src/utils/getIcon";
import { t } from "ttag";
import { IconButtonAnimate } from "../../../components/animate";
import MenuPopover from "../../../components/others/MenuPopover";
import MyAvatar from "../../../components/others/MyAvatar";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { PATH_PAGE } from "../../../routes/paths";

// ----------------------------------------------------------------------

const MenuPopoverOverChat = styled(MenuPopover)(() => {
	return {
		zIndex: 9999, // above Smooch that uses z-index = 9998
	};
});

// ----------------------------------------------------------------------

export default function AccountPopover(props: { outsideSiteContext: boolean }) {
	const navigate = useNavigate();

	const { user, logout } = useAuth();

	const isMountedRef = useIsMountedRef();

	const { enqueueSnackbar } = useSnackbar();

	const { themeMode, onToggleMode } = useSettings();

	const [fullscreen, setFullscreen] = useState(false);
	// const { setIsOpen, isOpen } = useTour()

	const { setTourType, triggerTour } = useAppTour();
	const { nodesLoaded, MultiSitesOrigin } = useNodesContext();
	const toggleFullScreen = () => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
			setFullscreen(true);
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
			setFullscreen(false);
		}
	};

	const [open, setOpen] = useState<HTMLElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const handleLogout = async () => {
		try {
			await logout();

			if (isMountedRef.current) {
				handleClose();
			}
		} catch (error) {
			console.error(error);
			navigate(PATH_PAGE.page500, { replace: true });
			enqueueSnackbar(t`Unable to logout!`, { variant: "error" });
		}
	};

	// const handleIntroTour = () => {
	//   return
	// }
	// const handleIntroTourClose = () => {
	//   handleIntroTour(),
	//   handleClose()
	// }

	// const { openTour, setOpenTour } = useAppTour()

	const handleIntroTourClose = (type: "default" | "updates") => {
		setTourType(type);
		triggerTour();
		handleClose();
	};

	// const MENU_OPTIONS = [
	//   { label: {t`Settings`}, icon: Iconify("ph:sliders-duotone"), onclick:handleClose, linkTo: '/user/account'},
	//   { label: {t`Dark mode`}, icon: Iconify("ph:moon-duotone"), onclick:handleClose, linkTo: ''},
	//   { label: {t`Light mode`}, icon: Iconify("ph:sun-duotone"), onclick:handleClose, linkTo: ''},
	//   { label: {t`Fullscreen`}, icon: Iconify("ph:corners-out-duotone"), onclick:handleClose, linkTo: ''},
	//   { label: {t`Logout`},  icon: Iconify("ph:sign-out-duotone"), onclick:handleLogout, linkTo:'/'},
	// ]
	return (
		<>
			<IconButtonAnimate
				onClick={handleOpen}
				id="accountPopover-button"
				size="large"
				sx={{
					p: 0,
					ml: "4px",
					...(open && {
						"&:before": {
							zIndex: 1,
							// content: "''",
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							position: "absolute",
							// bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
							"&:hover": {
								backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.2),
							},
						},
					}),
				}}
			>
				<MyAvatar />
			</IconButtonAnimate>

			<MenuPopoverOverChat
				open={Boolean(open)}
				id="accountPopover-content"
				anchorEl={open}
				onClose={handleClose}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					minWidth: "250px",
					maxWidth: "100%",
					"& .MuiMenuItem-root": {
						typography: "body2",
						borderRadius: 0.75,
					},
				}}
			>
				<Box sx={{ my: 1.5, px: 2.5, maxWidth: 300 }}>
					<Typography variant="subtitle2" noWrap>
						{user?.displayName}
					</Typography>
					<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
						{user?.email}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: "dashed" }} />
				<Stack sx={{ p: 1 }}>
					<MenuItem
						key="Settings"
						to="/settings"
						component={RouterLink}
						onClick={handleClose}
					>
						<ListItemIcon sx={{ mr: 0 }}>
							{Iconify("ph:sliders-duotone")}
						</ListItemIcon>
						<ListItemText>{t`My profile`}</ListItemText>
					</MenuItem>

					{themeMode === "light" ? (
						<MenuItem key="dark" id="darkMode" onClick={onToggleMode}>
							<ListItemIcon sx={{ mr: 0 }}>
								{Iconify("ph:moon-duotone")}
							</ListItemIcon>
							<ListItemText>{t`Dark mode`}</ListItemText>
						</MenuItem>
					) : (
						<MenuItem key="light" id="lightMode" onClick={onToggleMode}>
							<ListItemIcon sx={{ mr: 0 }}>
								{Iconify("ph:sun-duotone")}
							</ListItemIcon>
							<ListItemText>{t`Light mode`}</ListItemText>
						</MenuItem>
					)}
					{!props.outsideSiteContext && (
						<MenuItem
							key="Intro"
							// disabled
							// to='/'
							// component={RouterLink}
							onClick={() => handleIntroTourClose("default")}
						>
							<ListItemIcon sx={{ mr: 0 }}>
								{Iconify("ph:hand-waving-duotone")}
							</ListItemIcon>
							<ListItemText>{t`Intro tour`}</ListItemText>
						</MenuItem>
					)}
					{!props.outsideSiteContext &&
						(process.env.NODE_ENV === "development" ||
							window.location.host.includes("review-apps")) &&
						nodesLoaded &&
						MultiSitesOrigin &&
						MultiSitesOrigin.length > 2 && (
							<MenuItem
								key="Updates"
								onClick={() => handleIntroTourClose("updates")}
							>
								<ListItemIcon sx={{ mr: 0 }}>
									{Iconify("ph:hand-waving-duotone")}
								</ListItemIcon>
								<ListItemText>{t`Updates tour`}</ListItemText>
							</MenuItem>
						)}
					<MenuItem key="fullscreen" onClick={toggleFullScreen}>
						<ListItemIcon sx={{ mr: 0 }}>
							{fullscreen
								? Iconify("ph:corners-in-duotone")
								: Iconify("ph:corners-out-duotone")}
						</ListItemIcon>
						<ListItemText>
							{fullscreen ? t`Exit fullscreen` : t`Fullscreen`}
						</ListItemText>
					</MenuItem>
					{brandSetting.allow_dashboard_v1 && (
						<MenuItem
							key="dashboardv1"
							component="a"
							href={brandSetting.oldUrl}
						>
							<ListItemIcon sx={{ mr: 0 }}>
								{Iconify("ph:clock-counter-clockwise-duotone")}
							</ListItemIcon>
							<ListItemText>{t`Go back to the old version`}</ListItemText>
						</MenuItem>
					)}
					<MenuItem key="Logout" onClick={handleLogout}>
						<ListItemIcon sx={{ mr: 0 }}>
							{Iconify("ph:sign-out-duotone")}
						</ListItemIcon>
						<ListItemText>{t`Logout`}</ListItemText>
					</MenuItem>
				</Stack>
			</MenuPopoverOverChat>
		</>
	);
}
