import type { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingScreen from "src/components/others/LoadingScreen";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

// ----------------------------------------------------------------------

type GuestGuardProps = {
	children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
	const { isAuthenticated, isInitialized } = useAuth();
	const location = useLocation();
	const needRedirectToSettingsArray = [
		"/auth/define-password",
		"/auth/reset-password",
	];
	const needRedirectToSettings = needRedirectToSettingsArray.some((item) =>
		location.pathname.includes(item),
	);
	if (isAuthenticated && needRedirectToSettings && isInitialized) {
		return <Navigate to={PATH_DASHBOARD.settings_password} />;
	} else if (isAuthenticated && !needRedirectToSettings && isInitialized) {
		return <Navigate to={PATH_DASHBOARD.root} />;
	}

	return isInitialized ? <>{children}</> : <LoadingScreen />;
}
