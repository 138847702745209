import { useTheme } from "@mui/material";
import * as aq from "arquero";
import type ColumnTable from "arquero/dist/types/table/column-table";
import { differenceInCalendarDays, formatISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { max, sortBy, uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	createContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useParams } from "react-router";
import { SpecialDay } from "src/@types/SpecialDay";
import { ElectricityTypes, TemperatureTypes } from "src/@types/charts";
import type { APIGraphSeries } from "src/@types/graphSeries";
import { meteringplanObject } from "src/@types/installation";
import type { MagicButtonGraphState } from "src/@types/magic";
import {
	type ChartDataByPeriod,
	type PeriodData,
	type PeriodDataTable,
	getAggregatedData,
	getPeriodColumn,
} from "src/components/echarts/aggregate";
import { restrictPeriod } from "src/components/echarts/filters";
import { BENCHMARK_AUXILIARIES_ID, BENCHMARK_VENTILATION_ID } from "src/config";
import useAuth from "src/hooks/useAuth";
import useBenchmarkContext from "src/hooks/useBenchmarkContext";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { getBenchmark } from "src/services/service";
import { getMCByInstallation } from "src/utils/arquero/aggregateGroup";
import {
	mergeTwoColumns,
	restrictTablePeriod,
} from "src/utils/arquero/arqueroUtils";
import getBenchmarkSeries from "src/utils/getBenchmarkSeries";
import { Frequency, startOfPeriod } from "src/utils/timeFormatter";
import { useLazyEffect } from "src/utils/useLazyEffect";
import {
	GetOccupiedDays,
	GetOccupiedDaysFromMetadata,
} from "src/utils/weekDays";
import { t } from "ttag";
import { chartsDataApi } from "../services/service";
import { dataEvolutionSiteColors } from "./BenchmarkContext";
import { getSurface } from "./magicButtonContext";

// ----------------------------------------------------------------------

export const ComparisonTypes: (ElectricityTypes | undefined)[] = [
	ElectricityTypes.CompareTypicalDay,
	ElectricityTypes.CompareTypicalWeek,
	ElectricityTypes.CompareEnergy,
];

export const CategoryTypes: (ElectricityTypes | undefined)[] = [
	ElectricityTypes.CompareTypicalDay,
	ElectricityTypes.CompareTypicalWeek,
	ElectricityTypes.CompareEnergy,
	ElectricityTypes.PluriannualEvolution,
];

const constSelectedDaysTypes = [
	ElectricityTypes.TypicalDay,
	ElectricityTypes.CompareTypicalDay,
] as const;

export const SelectedDaysTypes: (ElectricityTypes | undefined)[] = [
	...constSelectedDaysTypes,
];

// Order matters for the UI, and the first item of the array will be taken as default
export const allowedSpecialDays = (
	chartType: ElectricityTypes,
): SpecialDay[] => {
	switch (chartType) {
		case ElectricityTypes.TypicalDay:
			// return [SpecialDay.OccupiedUnoccupiedDays]
			return [
				SpecialDay.OccupiedUnoccupiedDays,
				SpecialDay.OccupiedDays,
				SpecialDay.UnoccupiedDays,
			];
		case ElectricityTypes.CompareTypicalDay:
			return [SpecialDay.OccupiedDays, SpecialDay.UnoccupiedDays];
		default:
			return [];
	}
};

type ChartDataType = {
	Electricity: APIGraphSeries[];
	Temperature: APIGraphSeries[];
	SecondaryElectricity: APIGraphSeries[];
	SecondaryTemperature: APIGraphSeries[];
	Surface?: APIGraphSeries;
	Sites?: APIGraphSeries;
};

export type ChartDataContent = ChartDataType & {
	compatibleType: (val: ElectricityTypes) => boolean;
};

export type apiDataKey = keyof ChartDataType;

const EnergyTypes = [
	ElectricityTypes.EnergyDay,
	ElectricityTypes.EnergyWeek,
	ElectricityTypes.EnergyMonth,
	ElectricityTypes.EnergyYear,
];

function defaultChartData(elecType: ElectricityTypes) {
	return {
		Electricity: [] as APIGraphSeries[],
		Temperature: [] as APIGraphSeries[],
		SecondaryElectricity: [] as APIGraphSeries[],
		SecondaryTemperature: [] as APIGraphSeries[],
		Surface: undefined as APIGraphSeries | undefined,
		Sites: undefined as APIGraphSeries | undefined,
		compatibleType: (val: ElectricityTypes) => {
			return (
				val === elecType ||
				(val === ElectricityTypes.EnergyDonut && EnergyTypes.includes(elecType))
			);
		},
	};
}

export enum DataStates {
	Ready = "Ready",
	Empty = "Empty",
	Error = "Error",
	NotReady = "NotReady",
}

export namespace DataStates {
	export function isLoaded(state: DataStates): boolean {
		return state !== DataStates.NotReady;
	}

	export function stateLabel(state: DataStates): string {
		switch (state) {
			case DataStates.Ready:
				return t`Data is ready`;
			case DataStates.Error:
				return t`Error when loading data`;
			case DataStates.NotReady:
				return t`Not ready`;
			case DataStates.Empty:
				return t`No available data on this period`;
		}
	}
}

const ChartOptionsContext = createContext<{
	ChartData: ChartDataContent;
	dataReady: DataStates;
	pausedLoopCtx: boolean;
	setpausedLoopCtx: Dispatch<SetStateAction<boolean>>;
	dataByPeriod: ChartDataByPeriod;
	legendReady: boolean;
	setLegendReady: Dispatch<SetStateAction<boolean>>;
	benchmarkValidSitesData?: ColumnTable;
	multisiteDataRatios: [number, number][];
}>({
	ChartData: defaultChartData(ElectricityTypes.EnergyDay),
	dataReady: DataStates.NotReady,
	pausedLoopCtx: false,
	setpausedLoopCtx: () => {},
	dataByPeriod: {},
	legendReady: false,
	setLegendReady: () => {},
	benchmarkValidSitesData: undefined,
	multisiteDataRatios: [],
});

// ----------------------------------------------------------------------

type ChartOptionsProviderProps = {
	children: ReactNode;
	state: MagicButtonGraphState;
	checkSiteInUrl?: boolean;
};

function ChartOptionsProvider({
	children,
	state,
	checkSiteInUrl = true,
}: ChartOptionsProviderProps) {
	const [ChartData, setChartData] = useState(defaultChartData(state.chartType));
	const [dataReady, setDataReady] = useState(DataStates.NotReady);
	const [dataByPeriod, setDataByPeriod] = useState<ChartDataByPeriod>({});
	const [storedDataInstallation, setStoredDataInstallation] = useState("");
	const [storedDataCharType, setStoredDataCharType] = useState("");

	const [legendReady, setLegendReady] = useState(false);
	const [benchmarkValidSitesData, setBenchmarkValidSitesData] = useState<
		ColumnTable | undefined
	>();
	const [multisiteDataRatios, setMultisiteDataRatios] = useState<
		[number, number][]
	>([]);
	const referenceKey = useRef("");
	const [pausedLoopCtx, setpausedLoopCtx] = useState(false);
	const { doLogout } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const { Site, siteLoading, macroCategories } = useSiteContext();
	const { MultiSitesInstallations } = useNodesContext();
	const { dataCumul, dataEvolution, dataEvolutionSite, setBenchmarkState } =
		useBenchmarkContext();
	const params = useParams();
	const theme = useTheme();

	const buildKey = (state: MagicButtonGraphState): string => {
		return JSON.stringify([
			state.installation,
			state.chartType,
			state.temperature,
			state.temperature === TemperatureTypes.DegreeDay
				? [state.cdd, state.hdd]
				: "-",
			state.mainDates.dateRange,
			state.compareDates.dateRange,
			state.selectedDays,
			state.alertPeriodDates?.dateRange,
		]);
	};

	useEffect(() => {
		if (
			siteLoading ||
			(checkSiteInUrl && params.node && params.node !== state.site?.uuid) ||
			(params.customer && params.customer !== state.node)
		) {
			setDataReady(DataStates.NotReady);
			return;
		}

		if (state.site === undefined) return;

		// Normally, we avoid updating the state with identical values, but check to be sure
		const key = buildKey(state);
		if (key === referenceKey.current) return;

		// When the key changes, immediately set the data as NotReady, even before the useLazyEffect
		setDataReady(DataStates.NotReady);
	}, [siteLoading, state, params.customer, state.chartType]);

	useLazyEffect(
		() => {
			const needsAggregation = [
				ElectricityTypes.EnergyDay,
				ElectricityTypes.EnergyWeek,
				ElectricityTypes.EnergyMonth,
				ElectricityTypes.EnergyYear,
				ElectricityTypes.PluriannualEvolution,
				ElectricityTypes.EnergyDonut,
			].includes(state.chartType);
			setStoredDataCharType(state.chartType);
			if (
				siteLoading ||
				(checkSiteInUrl && params.node && params.node !== state.site?.uuid)
			) {
				// console.log('ChartOptionsProvider ignore state change during siteLoading')
				setDataReady(DataStates.NotReady);
				return;
			}

			if (state.site === undefined) return;

			// Normally, we avoid updating the state with identical values, but check to be sure
			const key = buildKey(state);
			if (key === referenceKey.current) return;

			console.debug(
				"ChartOptionsProvider UseEffect",
				referenceKey.current,
				"->",
				key,
			);
			referenceKey.current = key;

			const dateRange = state.mainDates.dateRange;

			setDataReady(DataStates.NotReady);

			if (
				state.installation === "" ||
				dateRange[0] === null ||
				dateRange[1] === null
			) {
				setChartData(defaultChartData(state.chartType));
				setDataReady(DataStates.Empty);
				setDataByPeriod({});
				return;
			}

			let calls = [];

			// cancellation: see https://stackoverflow.com/questions/53861916/canceling-an-axios-rest-call-in-react-hooks-useeffects-cleanup-failing
			let unmounted = false;
			const controller = new AbortController();

			let installationMetadata = state.installationObject?.metadata;
			let metadata_for_occupancy =
				installationMetadata !== undefined &&
				"openingSchedule" in installationMetadata &&
				"specificEquipments" in installationMetadata &&
				installationMetadata.specificEquipments &&
				installationMetadata.specificEquipments.includes(
					"specific_opening_schedule",
				)
					? installationMetadata
					: state.site && state.site.metadata;

			const occupationMask = GetOccupiedDaysFromMetadata(
				metadata_for_occupancy,
				"occupationMask",
			);
			const unoccupationMask = GetOccupiedDaysFromMetadata(
				metadata_for_occupancy,
				"unoccupationMask",
			);

			if (
				state.chartType === ElectricityTypes.TypicalDay &&
				state.selectedDays === SpecialDay.OccupiedUnoccupiedDays
			) {
				if (occupationMask !== "0000000") {
					calls.push(
						chartsDataApi(
							key,
							"Electricity",
							state.chartType,
							state,
							dateRange,
							state.site,
							{ days: occupationMask },
							controller.signal,
						),
					);
				}
				if (unoccupationMask !== "0000000") {
					calls.push(
						chartsDataApi(
							key,
							"SecondaryElectricity",
							state.chartType,
							state,
							dateRange,
							state.site,
							{ days: unoccupationMask },
							controller.signal,
						),
					);
				}
			} else if (state.chartType === ElectricityTypes.CompareTypicalDay) {
				let days: any = {};

				if (
					(state.selectedDays === SpecialDay.OccupiedDays ||
						state.selectedDays === SpecialDay.OccupiedUnoccupiedDays) &&
					occupationMask !== "0000000"
				) {
					days = { days: occupationMask };
				} else if (
					state.selectedDays === SpecialDay.UnoccupiedDays &&
					unoccupationMask !== "0000000"
				) {
					days = { days: unoccupationMask };
				}
				// TODO 4 appels  : occupied/main, occupied/compare, unoccupied/main, unoccupied/compare
				calls = [
					chartsDataApi(
						key,
						"Electricity",
						state.chartType,
						state,
						state.mainDates.dateRange,
						state.site,
						days,
						controller.signal,
					),
					chartsDataApi(
						key,
						"SecondaryElectricity",
						state.chartType,
						state,
						state.compareDates.dateRange,
						state.site,
						days,
						controller.signal,
					),
				];
			} else {
				// calls for min / max date to store all data.
				calls = [
					chartsDataApi(
						key,
						"Electricity",
						state.chartType,
						state,
						needsAggregation
							? [state.minDate, state.maxDate]
							: state.mainDates.dateRange,
						state.site,
						{},
						controller.signal,
					),
				];
				if (
					state.temperature === TemperatureTypes.DegreeDay ||
					state.temperature === TemperatureTypes.Temperature
				) {
					calls.push(
						chartsDataApi(
							key,
							"Temperature",
							state.temperature,
							state,
							needsAggregation
								? [state.minDate, state.maxDate]
								: state.mainDates.dateRange,
							state.site,
							{},
							controller.signal,
						),
					);
				}
			}

			// Benchmark monosite: Total by measuring point by end-use
			if (state.chartType === ElectricityTypes.Benchmark) {
				const controller = new AbortController();
				setStoredDataCharType(state.chartType);
				getBenchmark(
					state.site.metering_plan.reduce(
						(benchmarkInsta: string[], installation: meteringplanObject) => {
							if (installation.has_data_for_dashboard) {
								benchmarkInsta.push(installation.data_id);
							}
							return benchmarkInsta;
						},
						[] as string[],
					),
					controller.signal,
				).then((responseTable: ColumnTable) => {
					if (
						responseTable.size > 0 &&
						state.site &&
						state.mainDates.dateRange[0] &&
						state.mainDates.dateRange[1] &&
						macroCategories
					) {
						// save result from API in state to
						responseTable = mergeTwoColumns(
							aq,
							responseTable,
							BENCHMARK_VENTILATION_ID.toString(),
							BENCHMARK_AUXILIARIES_ID.toString(),
						);
						let period = state.mainDates.dateRange.map(
							(date) =>
								date && formatISO(date as Date, { representation: "date" }),
						) as [string, string];
						let maxDaysCount =
							differenceInCalendarDays(
								state.mainDates.dateRange[1],
								state.mainDates.dateRange[0],
							) + 1;
						let restrictedTable = restrictTablePeriod(
							aq,
							responseTable,
							period,
						);
						const siteContent = state.site;
						const surfaceData = state.site.metering_plan.reduce(
							(data, installation) => {
								data.installation_uuid.push(installation.data_id);
								data.installation_label.push(installation.label);
								data.site_label.push(siteContent.label);
								data.site_uuid.push(siteContent.uuid);
								data.surface.push(getSurface(installation, Site));
								// truncate dates to only have ISO format
								data.date_begin_customer.push(
									installation.date_begin_customer
										? new Date(installation.date_begin_customer)
										: null,
								);
								data.date_end_customer.push(
									installation.date_end_customer
										? new Date(installation.date_end_customer)
										: null,
								);
								return data;
							},
							{
								installation_uuid: [],
								installation_label: [],
								surface: [],
								site_label: [],
								site_uuid: [],
								date_begin_customer: [],
								date_end_customer: [],
							} as {
								installation_uuid: string[];
								installation_label: string[];
								surface: (number | null)[];
								site_label: string[];
								site_uuid: string[];
								date_begin_customer: (Date | null)[];
								date_end_customer: (Date | null)[];
							},
						);

						const installationTable = aq.table(surfaceData);

						let zoneDataCumul = getMCByInstallation(
							aq,
							restrictedTable,
							maxDaysCount,
							installationTable,
						);
						zoneDataCumul.print();

						let dataByDay = {
							EnergyDay: restrictedTable,
							EnergyWeek: null,
							EnergyMonth: null,
							EnergyYear: null,
							EnergyTotal: null,
						};
						let zoneBenchmarkResult = getBenchmarkSeries(
							zoneDataCumul,
							macroCategories,
							theme,
							setBenchmarkValidSitesData,
							state.ratio,
						);
						setBenchmarkState({ benchmarkLoaded: true });
						setDataByPeriod({ Electricity: dataByDay });
						setChartData({
							...defaultChartData(state.chartType),
							Electricity: zoneBenchmarkResult.benchmarkSeries,
							Surface: zoneBenchmarkResult.surfaceSeries,
						});
						setDataReady(
							zoneBenchmarkResult.installations.length > 0
								? DataStates.Ready
								: DataStates.Empty,
						);
					}
				});
			}

			const categoryOrder: any[] =
				state.installationObject?.categories.map((cat) => cat.id) || [];
			try {
				Promise.all(calls).then(
					(response) => {
						// console.log(response, 'response dta');

						const res = defaultChartData(state.chartType);
						const aggregatedData =
							state.installation === storedDataInstallation
								? state.dashboard === "monosite" &&
									storedDataCharType === ElectricityTypes.Benchmark &&
									state.chartType !== storedDataCharType
									? {}
									: { ...dataByPeriod }
								: {};
						let validResponse = false;
						for (const [callIdentifier, key, data] of response) {
							if (callIdentifier !== referenceKey.current) {
								console.info("Ignore API response that is no longer useful");
								continue;
							}

							// data.forEach((item) => (item.unique_id = uniqueId()));
							data.forEach((item) => {
								item.unique_id = uniqueId();
								return item.unique_id;
							});

							let newData = data;
							if (needsAggregation && Site) {
								const elecType =
									state.temperature &&
									(key === "Temperature" || key === "SecondaryTemperature")
										? state.temperature
										: key;
								let dataForElecType =
									aggregatedData[elecType as keyof ChartDataByPeriod];
								if (dataForElecType === undefined) {
									dataForElecType = getAggregatedData(
										data,
										elecType as keyof ChartDataByPeriod,
										Site.timezone,
									);
									aggregatedData[elecType as keyof ChartDataByPeriod] =
										dataForElecType;
								}

								let startDate = state.mainDates.dateRange[0];
								const endDate = state.mainDates.dateRange[1];
								if (startDate !== null && endDate !== null) {
									// get start of day because we aggregate on day (= begins at midnight) and startDate can be after midnight so it would exclude the first day
									startDate = startOfPeriod(
										zonedTimeToUtc(startDate, Site.timezone),
										Site.timezone,
										Frequency.Day,
									);
									let typeKey = state.chartType;
									// reaggreagate data after filtering first by mainDates.dateRange
									const filterData = restrictPeriod(
										data,
										[startDate, endDate],
										Site.timezone,
										true,
									);
									if (state.chartType === ElectricityTypes.EnergyDonut)
										typeKey = ElectricityTypes.EnergyDay;
									else if (
										state.chartType === ElectricityTypes.PluriannualEvolution
									)
										typeKey = ElectricityTypes.EnergyMonth;
									newData = getAggregatedData(
										filterData,
										elecType as keyof ChartDataByPeriod,
										Site.timezone,
										typeKey as keyof PeriodData,
									)[typeKey as keyof PeriodData];
								}
							}

							validResponse = true;
							switch (key) {
								case "Electricity":
								case "SecondaryElectricity":
									// Sort items according to the defined order of the installation.
									// Stable sort, so categories without defined order will keep the same order.
									res[key] = sortBy(newData, (item) =>
										categoryOrder.indexOf(item.itemid || 0),
									);
									break;

								case "Temperature":
								case "SecondaryTemperature":
									res[key] = newData;
									break;
							}
						}

						if (
							validResponse &&
							!unmounted &&
							state.installation &&
							!(
								state.dashboard === "monosite" &&
								state.chartType === ElectricityTypes.Benchmark
							)
						) {
							setChartData(res);
							setDataReady(
								res.Electricity.length > 0 &&
									(max(res.Electricity.map((series) => series.data.length)) ||
										0) > 0
									? DataStates.Ready
									: DataStates.Empty,
							);
							setDataByPeriod(aggregatedData);
							setStoredDataInstallation(state.installation);
						}
					},
					(error_response: any) => {
						if (error_response.message !== "canceled") {
							console.error("error_response", error_response);
						}

						if (error_response.status === 403) {
							// not supposed to happen with the BroadcastChannel("AuthenticatedStatus"),
							// except on browsers that don't implement BroadcastChannel (mobile browsers except Firefox)
							enqueueSnackbar(
								t`You have been disconnected from another page.`,
								{ variant: "warning", autoHideDuration: 15000 },
							);
							doLogout().then(
								() => {},
								() => {},
							);
						}

						if (!unmounted) {
							setChartData(defaultChartData(state.chartType));
							setDataByPeriod({});
							setDataReady(DataStates.Error);
						}
					},
				);
			} catch (err) {
				console.error(err);
			}

			// return clean-up function that is called on unmount
			return () => {
				unmounted = true;
				controller.abort();
				referenceKey.current = "";
			};
		},
		[siteLoading, state],
		150,
	); // useLazyEffect: avoid spamming the API immediately if the state might change soon

	useEffect(() => {
		// Multisite benchmark
		if (state.dashboard === "multisite" && macroCategories) {
			if (
				state.chartType === ElectricityTypes.Benchmark &&
				MultiSitesInstallations &&
				dataCumul
			) {
				const benchmarkResult = getBenchmarkSeries(
					dataCumul,
					macroCategories,
					theme,
					setBenchmarkValidSitesData,
					state.ratio,
				);

				setChartData({
					...defaultChartData(state.chartType),
					Electricity: benchmarkResult.benchmarkSeries,
					Surface: benchmarkResult.surfaceSeries,
				});
				setDataReady(
					benchmarkResult.installations.length > 0
						? DataStates.Ready
						: DataStates.Empty,
				);
			} else if (
				[
					ElectricityTypes.EnergyDay,
					ElectricityTypes.EnergyWeek,
					ElectricityTypes.EnergyMonth,
					ElectricityTypes.EnergyYear,
				].includes(state.chartType) &&
				state.dataEvolutionType &&
				dataEvolution &&
				dataEvolutionSite
			) {
				const periodColumn = getPeriodColumn(
					state.chartType as keyof PeriodDataTable,
				);
				const isEndUse = state.dataEvolutionType === "end-use";
				const dataTables = isEndUse ? dataEvolution : dataEvolutionSite;
				const columnsFilter = isEndUse
					? ["total", aq.matches(/\d+/)]
					: aq.not([
							periodColumn,
							"site_count",
							"active_site_count",
							"cumul_active_site",
							"active_installation_uuid",
							"data_ratio",
						]);
				const evolutionSeries: APIGraphSeries[] = [];
				let siteSeries: APIGraphSeries | undefined;
				let tableForPeriod =
					dataTables[state.chartType as keyof PeriodDataTable];
				// always use dataEvolution to compute siteSeries
				const siteDataTable =
					dataEvolution[state.chartType as keyof PeriodDataTable];

				let dataRatios: [number, number][] = [];
				if (tableForPeriod !== null) {
					let datesValues = tableForPeriod.array(periodColumn);
					// transform week, month, year string into UTC timestamp
					datesValues = datesValues.map((period: string) =>
						new Date(period).getTime(),
					);

					dataRatios = tableForPeriod
						.array("data_ratio")
						.map((ratio: number, index: number) => [datesValues[index], ratio]);

					if (siteDataTable) {
						const siteData = siteDataTable
							.array("active_site_count")
							.map((nbSites: number, index: number) => [
								datesValues[index],
								nbSites,
							]);
						// create line serie for surface of all sites
						siteSeries = {
							unique_id: uniqueId(),
							label: t`Active sites`,
							type: "line",
							color: "rgb(107, 174, 214)",
							data: siteData,
						};
					}

					// select columns that matches filters => data by macrocategory or sites
					tableForPeriod = tableForPeriod.select(columnsFilter);
					// columns can be representing Macrocategories (by end_use) or Site Label (by site)
					let columns = tableForPeriod.columnNames();
					// columns are added in reversed in eCharts: we want top of stack to be the first mc / most consuming site
					columns = columns.reverse();
					columns.forEach((column, columnIndex) => {
						if (tableForPeriod) {
							const isTotal = column === "total";
							const isOtherSites = column === "otherSites";
							const colValues = tableForPeriod.array(column);
							const seriesId =
								isEndUse && !isTotal ? Number.parseInt(column) : column;
							const macroCategory = isEndUse
								? macroCategories.find((item) => item.id === seriesId)
								: null;
							let label = column;
							if (isEndUse && macroCategory) label = macroCategory.label;
							else if (isTotal) label = t`Total energy`;
							else if (isOtherSites) label = t`Other sites`;

							if ((isEndUse && macroCategory) || !isEndUse || isTotal) {
								evolutionSeries.push({
									unique_id: uniqueId(),
									label: label,
									itemid: isTotal ? -1 : seriesId,
									description:
										isEndUse && macroCategory
											? macroCategory.description
											: undefined,
									type: isTotal ? "total" : "stacked_element",
									color: isTotal
										? "#42374D"
										: isEndUse && macroCategory
											? macroCategory.color
											: isOtherSites
												? dataEvolutionSiteColors[
														dataEvolutionSiteColors.length - 1
													]
												: dataEvolutionSiteColors[
														columns.length - columnIndex - 1
													],
									data: colValues.map(
										(value: number | undefined, index: number) =>
											value === undefined
												? [datesValues[index], 0]
												: [datesValues[index], value],
									),
								});
							}
						}
					});
				}
				setDataByPeriod({ Electricity: dataTables });
				setChartData({
					...defaultChartData(state.chartType),
					Electricity: evolutionSeries,
					Sites: siteSeries,
				});
				setDataReady(DataStates.Ready);
				setMultisiteDataRatios(dataRatios);
			}
		}
	}, [
		dataCumul,
		macroCategories,
		state.ratio,
		state.chartType,
		state.dataEvolutionType,
	]);

	return (
		<ChartOptionsContext.Provider
			value={{
				ChartData,
				dataReady,
				pausedLoopCtx,
				setpausedLoopCtx,
				dataByPeriod,
				legendReady,
				setLegendReady,
				benchmarkValidSitesData,
				multisiteDataRatios,
			}}
		>
			{children}
		</ChartOptionsContext.Provider>
	);
}

export { ChartOptionsContext, ChartOptionsProvider };
