import { type ReactNode, createContext, useEffect, useReducer } from "react";
import {
	type Category,
	type DateKeys,
	type DateRangeDate,
	DateSelection,
	MagicActionTypes,
	type MagicButtonActions,
	type MagicButtonContextType,
	type MagicButtonState,
	type MagicButtonUserSettings,
} from "src/@types/magic";

import Ajv from "ajv";
import { formatISO, isValid, parseISO, subDays } from "date-fns";
import { cloneDeep, isArray, isEqual } from "lodash";
import {
	matchPath,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import type { SiteContent } from "src/@types/SiteContent";
import { type SelectedDays, SpecialDay } from "src/@types/SpecialDay";
import {
	ChartTypesUrl,
	ElectricityTypes,
	TemperatureTypes,
} from "src/@types/charts";
import {
	installationZonedDates,
	loadcurveZonedDates,
	type meteringplanBaseObject,
} from "src/@types/installation";
import {
	Options,
	ShortcutString,
	Shortcuts,
	getShortcutFunctions,
	labelFromDates,
	optionsMask,
	shortcutLabel,
	stringToShortcut,
} from "src/components/ShortCut/shortCut";
import {
	CategoryTypes,
	ComparisonTypes,
	SelectedDaysTypes,
	allowedSpecialDays,
} from "src/contexts/ChartOptionsContext";
import useBenchmarkContext from "src/hooks/useBenchmarkContext";
import useLocales from "src/hooks/useLocales";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import schema from "src/schemas/MagicButtonUserSettings.json";
import benchmarkZonedDates from "src/utils/benchmarkZonedDates";
import { intersection } from "src/utils/dateRange";
import { analysisUrl } from "src/utils/links";
import {
	parseSelectedDays,
	serializeSelectedDays,
} from "src/utils/serializers";
import { t } from "ttag";

const ajv = new Ajv();
const validateMagicButtonUserSettings = ajv.compile(schema);

const showDiff = (
	_prevState: MagicButtonState,
	_finalState: MagicButtonState,
) => {
	if (process.env.NODE_ENV === "development") {
		// console.log('diff', JSON.stringify(updatedDiff(prevState, finalState), undefined, 4))
	}
};

export const parseValidateISO = (val: string) => {
	const res = parseISO(val);
	if (isValid(res)) return res;
	return null;
};

export const validRange = (rng: DateRangeDate | null): rng is [Date, Date] => {
	if (rng === null || rng[0] === null || rng[1] === null) {
		return false;
	}
	return rng[0] <= rng[1];
};
// const setValidShortcutsMax = (validShortcutsmaxDays:number) => {
//   // useEffect(() => {
//     return enqueueSnackbarCustom(t`Dates reduced to ${validShortcutsmaxDays} days (max duration between 2 dates)`, { variant: "info" })
//   // }, [])
// }
const sameRange = (rng1: DateRangeDate | null, rng2: DateRangeDate | null) => {
	if (!validRange(rng1) || !validRange(rng2)) {
		return false;
	}
	return (
		dateFormatISO(rng1[0]) === dateFormatISO(rng2[0]) &&
		dateFormatISO(rng1[1]) === dateFormatISO(rng2[1])
	);
};

const total: Category = {
	id: -1,
	label: "Total",
	color: "#42374D",
};

const defaultUserSettings: MagicButtonUserSettings = {
	conso: "consumption",
	ratio: "without",
	temperature: TemperatureTypes.DegreeDay,
	yAxis: "auto",
	withUnoccupation: true,
	withEvents: true,
};

const storedUserSettings = Object.keys(
	defaultUserSettings,
) as (keyof MagicButtonState)[];

const readLocalStorage = <T extends keyof MagicButtonUserSettings>(
	key: T,
): MagicButtonUserSettings[T] => {
	const stored = localStorage.getItem(key);
	if (stored) {
		const parsed = JSON.parse(stored);
		const objForValidation: MagicButtonUserSettings = {
			...defaultUserSettings,
			[key]: parsed,
		};
		const valid = validateMagicButtonUserSettings(objForValidation);
		if (valid) {
			return parsed;
		} else {
			// the value is invalid
			console.error(
				"invalid localStorage value for",
				key,
				stored,
				JSON.stringify(validateMagicButtonUserSettings.errors),
			);
			localStorage.setItem(key, JSON.stringify(defaultUserSettings[key]));
		}
	}
	return defaultUserSettings[key];
};

// TODO : Prendre en compte les valeurs initiales de l'url et les préférences de l'utilisateur (localStorage)
export const initialState = (): MagicButtonState => {
	return {
		dashboard: "monosite",
		// node
		node: "", // TODO : Sélectionner le node de l’url sinon le dernier site visité par l'utilisateur sinon le 1er site de la liste
		// site: {},
		// currency: null,
		// co2: null,
		// people: null,
		// installation
		installation: "",
		installationLabel: "", // Selon installation sélectionnée
		minDate: null, // Selon installation sélectionnée
		maxDate: null, // Selon installation sélectionnée
		surface: null, // TODO surface de l'installation (sinon surface du site selon type d'installation ?)
		hdd: null,
		cdd: null,

		// dates

		// dateRange
		mainDates: {
			dateRange: [null, null],
			shortcut: Shortcuts.AllHistory,
		},
		compareDates: {
			dateRange: [null, null],
			shortcut: Shortcuts.AllHistory,
		},
		alertPeriodDates: {
			dateRange: [null, null],
			shortcut: Shortcuts.AllHistory,
		},
		// dateStart: JSON.parse(localStorage.getItem("dateRange") || '{}').start || null,
		// dateEnd: JSON.parse(localStorage.getItem("dateRange") || '{}').end || null,

		// chart
		chartType: ElectricityTypes.EnergyMonth, // setup at runtime in MagicButtonProvider before calling useReducer

		// categories
		categories: [total], // A l'initialisation, seule la catéogie "Total" est affichée ou selon installation sélectionnée
		category: total.id, // Présélection systématique du Total (id: -1)
		categoryLabel: total.label,

		// days
		selectedDays: SpecialDay.OccupiedUnoccupiedDays,

		// options. localStorage si existant sinon valeurs par défaut stockées dans defaultUserSettings
		conso: readLocalStorage("conso"),
		ratio: readLocalStorage("ratio"),
		temperature: readLocalStorage("temperature"),
		yAxis: readLocalStorage("yAxis"),
		withUnoccupation: readLocalStorage("withUnoccupation"),
		withEvents: readLocalStorage("withEvents"),

		// only the state
		maxPower: null,
		maxEnergy: null,

		//sharedNotes
		sharedNotes: "",
		// alerts
		alertThreshold: null,
	};
};

export const findInstallation = (
	site: SiteContent,
	id: string,
): meteringplanBaseObject | null => {
	let result = searchLabelById(
		"data_id",
		id,
		site.metering_plan as meteringplanBaseObject[],
	);
	if (result === null) {
		result = searchLabelById(
			"data_id",
			id,
			site.virtual as meteringplanBaseObject[],
		);
	}

	// console.log(result, filter_access_date_begin(result!), result!.date_begin_customer, '[APPTRACKER result]')
	// check if the user has access to this installation
	if (!result?.user_access) {
		return null;
	}

	return result;
};

export const selectInstallation = (
	site: SiteContent,
	installationId?: string,
	previousInstallation?: string,
): meteringplanBaseObject => {
	let installation: meteringplanBaseObject | null = null;
	const filter_access = (installation: meteringplanBaseObject | null) =>
		installation?.user_access &&
		installation.has_data_for_dashboard &&
		installation.date_begin_customer !== null;

	if (installationId) {
		installation = findInstallation(site, installationId);
		if (installation !== null) {
			console.debug("Set installation from passed param");
		}
	}

	if (installation === null && previousInstallation) {
		installation = findInstallation(site, previousInstallation);
		if (installation !== null) {
			console.debug("Set installation from prevState");
		}
	}

	if (installation === null && site.default_installation) {
		installation = findInstallation(site, site.default_installation);
		if (installation !== null) {
			console.debug("Set installation from default_installation");
		}
	}

	if (!filter_access(installation) && site.default_installation) {
		installation = findInstallation(site, site.default_installation);
		if (installation !== null) {
			console.debug("Set installation from default_installation");
		}
		// enqueueSnackbar(t`This measurement point does not exist, change to the default measurement point.`, { variant: "error" });
	}

	if (installation === null && site.metering_plan.length > 0) {
		const all_uuid: string[] = [];
		const walkTree = (tree: meteringplanBaseObject[]) => {
			tree.forEach((item) => {
				all_uuid.push(item.data_id);
				if (item.children !== undefined) {
					walkTree(item.children);
				}
			});
		};
		walkTree(site.metering_plan);

		console.info(
			"Site: fallback to iterating installations",
			previousInstallation,
			site.default_installation,
		);
		for (const data_id of all_uuid) {
			installation = findInstallation(site, data_id);
			if (installation !== null) {
				console.debug("Set installation from iteration");
				break;
			}
		}
	}

	if (installation === null) {
		console.error("No usable installation for site", site.uuid);
		throw new Error("Installation is null");
	}

	// console.log(installation, 'installation')
	return installation;
};

export const selectInstallationSiteInfos = (
	installations: meteringplanBaseObject[],
	installationId?: string,
): meteringplanBaseObject | undefined => {
	if (installationId) {
		let selected: meteringplanBaseObject | undefined = undefined;
		installations.map((installation) => {
			// if selected === undefined to avoid mapping through children if installation was already found
			if (selected === undefined) {
				if (
					installation.data_id === installationId &&
					installation.user_access
				) {
					selected = installation;
				} else if (
					installation.children &&
					installation.has_children_with_user_access
				) {
					selected = selectInstallationSiteInfos(
						installation.children,
						installationId,
					);
				}
			}
		});
		return selected;
	} else {
		return installations.find(
			(installation) =>
				(installation.user_access &&
					installation.date_begin_customer !== null &&
					installation.can_view_metadata) ||
				(installation.has_children_with_user_access &&
					installation.has_children_view_metadata),
		);
	}
};

export const getSurface = (
	installation: meteringplanBaseObject | null,
	site: SiteContent | undefined,
) => {
	// Surface de l'installation
	// console.log(installation, site, 'need surface ? ')
	let surface = null;
	// Si "type de mesure" = "Site complet" ou "Composite - Site complet par usage", prendre surface = surface installation (si existante) sinon surface site. Pour les autres types, surface = surface installation.
	if (installation?.metadata !== undefined) {
		if (installation.metadata.surface) {
			surface = installation.metadata.surface;
		} else if (
			[
				"MAIN",
				"COMPOSITE_FULL_SITE_BREAKDOWN",
				"COMPOSITE_FULL_SITE_TGBT",
			].includes(installation.measure_type) &&
			site?.metadata.buildingSurface
		) {
			surface = site?.metadata.buildingSurface;
		}
	}
	return surface;
};

export const buildBasicState = (
	installation: meteringplanBaseObject,
	Site: SiteContent,
	useStorage = false,
): Pick<
	MagicButtonState,
	| "dashboard"
	| "site"
	| "node"
	| "installation"
	| "installationObject"
	| "installationLabel"
	| "surface"
	| "hdd"
	| "cdd"
	| "minDate"
	| "maxDate"
	| "categories"
	| "category"
	| "categoryLabel"
	| "selectedDays"
	| "temperature"
	| "withUnoccupation"
	| "withEvents"
	| "conso"
	| "ratio"
	| "yAxis"
	| "maxPower"
	| "maxEnergy"
	| "alertThreshold"
	| "sharedNotes"
> => {
	const { start, end } = installationZonedDates(installation, Site.timezone);
	return {
		dashboard: "monosite",
		site: Site,
		node: Site.uuid,
		installation: installation.data_id,
		installationObject: installation,
		installationLabel: installation.label,
		surface: getSurface(installation, Site),
		hdd: installation.metadata?.degree_day_references?.heating || null,
		cdd: installation.metadata?.degree_day_references?.cooling || null,
		minDate: start,
		maxDate: end,
		categories: installation.categories,
		category: total.id,
		categoryLabel: total.label,
		selectedDays: SpecialDay.OccupiedUnoccupiedDays,
		temperature: useStorage
			? readLocalStorage("temperature")
			: defaultUserSettings.temperature, // TemperatureTypes.DegreeDay,
		withUnoccupation: useStorage
			? readLocalStorage("withUnoccupation")
			: defaultUserSettings.withUnoccupation, // false,
		withEvents: useStorage
			? readLocalStorage("withEvents")
			: defaultUserSettings.withEvents, // false,
		conso: useStorage ? readLocalStorage("conso") : defaultUserSettings.conso, // "consumption",
		ratio: useStorage ? readLocalStorage("ratio") : defaultUserSettings.ratio, // "without",
		yAxis: useStorage ? readLocalStorage("yAxis") : defaultUserSettings.yAxis, // "auto",
		maxPower: null,
		maxEnergy: null,
		sharedNotes: installation.metadata.sharedNotes || "",
		alertThreshold: null,
	};
};

const getMinMaxDates = (
	installation: meteringplanBaseObject | null,
	site: SiteContent | undefined,
	chartType: ElectricityTypes,
) => {
	let start = null,
		end = null;
	if (installation) {
		if (chartType === ElectricityTypes.Loadcurve) {
			({ start, end } = loadcurveZonedDates(
				installation,
				site?.timezone || "UTC",
			));
		} else {
			({ start, end } = installationZonedDates(
				installation,
				site?.timezone || "UTC",
			));
		}
	}
	return { minDate: start, maxDate: end };
};

const extractInstallation = (
	installation: meteringplanBaseObject | null,
	site: SiteContent | undefined,
	prevState: Readonly<MagicButtonState>,
): Partial<MagicButtonState> => {
	// Mise à jour des catégories
	const categories = [total];
	if (
		installation &&
		installation.categories &&
		installation.categories.length > 0 &&
		installation.categories !== null &&
		installation.data_id !== null
	) {
		// console.log(installation.categories, 'categories')
		categories.push(...installation.categories);
	}

	// Reset the selected category to Total
	let cat = total;
	if (prevState.installation === "") {
		// except at first load where we try to use the data from the URL
		cat = searchLabelById("id", prevState.category, categories) || total;
	}

	let chartType = prevState.chartType;
	// Update ChartType if the dataset is not public
	if (installation !== null && !installation.public) {
		// force loadcurve for installations without public data
		chartType = ElectricityTypes.Loadcurve;
	} else if (chartType === ElectricityTypes.Loadcurve && installation?.public) {
		// when selecting the loadcurve of a public installation
		if (prevState.installation !== "" || installation.loadcurve_end === null) {
			// if there's no loadcurve or if we changed the installation, go to EnergyMonth
			chartType = ElectricityTypes.EnergyMonth;
		}
	}

	// reset selectedDays to the default value according to the chartType
	let selectedDays: SelectedDays =
		allowedSpecialDays(chartType)[0] || SpecialDay.OccupiedUnoccupiedDays;
	if (prevState.installation === "") {
		// except at first load where we try to use the data from the URL
		if ((prevState.selectedDays as string) in SpecialDay) {
			if (
				allowedSpecialDays(chartType).includes(
					prevState.selectedDays as SpecialDay,
				)
			) {
				// it is an allowed SpecialDay
				selectedDays = prevState.selectedDays;
			}
		} else {
			// it is a WeekDay[]
			selectedDays = prevState.selectedDays;
		}
	}

	// let initialRange: [Date|null, Date|null] = [null, null]

	return {
		installationObject: installation || undefined,
		installation: installation?.data_id || undefined,
		installationLabel: installation?.label || undefined,
		categories, // Refresh liste des catégories
		category: cat.id,
		categoryLabel: cat.label,
		surface: getSurface(installation, site),
		hdd: installation?.metadata?.degree_day_references?.heating || null,
		cdd: installation?.metadata?.degree_day_references?.cooling || null,
		sharedNotes: installation?.metadata.sharedNotes || "",
		chartType,
		selectedDays,
		...getMinMaxDates(installation, site, chartType), // refresh min/max dates based on chartType
	};
};

const resetDates = (
	chartType: ElectricityTypes | undefined,
):
	| (Partial<MagicButtonState> &
			Pick<MagicButtonState, "mainDates" | "compareDates">)
	| null => {
	if (chartType === undefined) return null;

	const option = optionsMask[chartType];
	if (option) {
		return {
			mainDates: {
				dateRange: [null, null],
				shortcut: option.default,
			},
			compareDates: {
				dateRange: [null, null],
				shortcut:
					option.compareDefault !== undefined
						? option.compareDefault
						: option.default,
			},
		};
	}

	return null;
};

const getCompareRange = (
	validShortcuts: Options | undefined,
	shortcut: Shortcuts,
	shortcutFns: Record<number, () => DateRangeDate>,
	installationRange: DateRangeDate,
): {
	dateRange: DateRangeDate;
	shortcut: Shortcuts;
} | null => {
	let defaultRange = intersection(shortcutFns[shortcut](), installationRange);
	if (defaultRange !== null) {
		return {
			dateRange: defaultRange,
			shortcut: shortcut,
		};
	}
	if (validShortcuts && validShortcuts.compareOptions) {
		// if it does not work, fallback to the other compare options
		let valid_shortcut: Shortcuts | null = null;
		validShortcuts.compareOptions.forEach((other_shortcut) => {
			if (
				defaultRange === null &&
				valid_shortcut === null &&
				other_shortcut !== shortcut
			) {
				defaultRange = intersection(
					shortcutFns[other_shortcut](),
					installationRange,
				);
				if (defaultRange !== null) valid_shortcut = other_shortcut;
			}
		});
		// return first default range found
		if (defaultRange !== null && valid_shortcut !== null) {
			return {
				dateRange: defaultRange,
				shortcut: valid_shortcut,
			};
		}
	}
	return null;
};

const getDateState = (
	state: Partial<MagicButtonState> &
		Pick<
			MagicButtonState,
			"chartType" | "site" | "minDate" | "maxDate" | "mainDates"
		>,
): Partial<MagicButtonState> => {
	// console.log('state changed detected in DateRange', state)
	// console.log('DateRange : ', value)
	const metering_plan = state.installationObject;

	const result: Partial<MagicButtonState> & Pick<MagicButtonState, DateKeys> = {
		mainDates: {
			dateRange: [null, null],
			shortcut: undefined,
		},
		compareDates: {
			dateRange: [null, null],
			shortcut: undefined,
		},
	};

	const initialRange = state.mainDates ? state.mainDates.dateRange : null;
	const initialCompareRange = state.compareDates
		? state.compareDates.dateRange
		: null;

	const installationRange: DateRangeDate = [state.minDate, state.maxDate];
	//@ts-ignore
	// const { enqueueSnackbar } = useSnackbar();

	const intersect = intersection(initialRange || null, installationRange);
	const intersectCompare = intersection(
		initialCompareRange || null,
		installationRange,
	);

	const validShortcuts = optionsMask[state.chartType];
	const validShortcutsCompare = optionsMask[state.chartType];

	const shortcutFns = getShortcutFunctions(state);

	if (validRange(intersect)) {
		// Dates from the searchParams
		result.mainDates.dateRange = intersect;
		// Prise en compte maxDays
		if (validShortcuts && validShortcuts.maxdays) {
			const intersection100Days = intersection(intersect, [
				subDays(intersect[1], validShortcuts.maxdays),
				intersect[1],
			]);
			result.mainDates.dateRange = intersection100Days as DateRangeDate;
			if (intersect > intersection100Days!) {
				if (installationRange !== undefined) {
					// console.log(state, 'installationRange')
					// setValidShortcutsMax(validShortcuts.maxdays)
				}
			}
		}
		// console.log('[getDateState] Use provided range', JSON.stringify(result.mainDates.dateRange))
	} else {
		let defaultRange: DateRangeDate | null = null;
		// No dates in searchParams

		// const keyState = state.key
		// Try to keep the shortcut from the state if possible
		let shortcut: Shortcuts | undefined;
		if (
			validShortcuts !== undefined &&
			state.mainDates?.shortcut &&
			validShortcuts.options.includes(state.mainDates?.shortcut)
		) {
			shortcut = state.mainDates?.shortcut;
			if (shortcut !== undefined) {
				defaultRange = intersection(shortcutFns[shortcut](), installationRange);
				if (defaultRange) {
					// console.log('[getDateState] build range from shortcut', shortcut, JSON.stringify(defaultRange))
					result.mainDates.dateRange = defaultRange;
					result.mainDates.shortcut = shortcut;
				}
			}
		}

		// if it does not work, use the default shortcut for this graph type
		if (!defaultRange) {
			shortcut = validShortcuts?.default as Shortcuts;
			if (shortcut !== undefined) {
				defaultRange = intersection(shortcutFns[shortcut](), installationRange);
				if (defaultRange) {
					// console.log('[getDateState] fallback to default shortcut', shortcut, JSON.stringify(defaultRange))
					result.mainDates.dateRange = defaultRange;
					result.mainDates.shortcut = shortcut;
				}
			}
		}

		if (defaultRange === null) {
			if (metering_plan)
				console.error(
					"No default range for this installation",
					metering_plan.data_id,
				);
			else console.error("No default range for this multisite");
		} else {
			// console.log('[getDateState] ', result)
		}
	}

	if (result.mainDates.shortcut === undefined) {
		// Check if the range matches a shortcut
		// console.log(result.mainDates.shortcut, 'result.mainDates.shortcut')
		const shortcutFns = getShortcutFunctions(state);
		const options = optionsMask[state.chartType]?.options || [];
		for (let index = options.length; index >= 0; index--) {
			// iterate in reverse order, larger shortcuts are better
			const option = options[index];
			const checkShorcut = shortcutFns[option];
			const rng = checkShorcut ? checkShorcut() : null;

			// restrict to dates of installation
			const intersect = intersection(rng, installationRange);

			if (sameRange(intersect, result.mainDates.dateRange)) {
				result.mainDates.shortcut = option;
				break;
			}
		}
	}

	// console.log(intersectCompare, 'installationRange')
	if (
		validRange(intersectCompare) &&
		state.compareDates?.shortcut === undefined
	) {
		// Dates from the searchParams
		result.compareDates.dateRange = intersectCompare;
		// Prise en compte maxDays
		if (validShortcuts && validShortcuts.maxdays) {
			const intersection100Days = intersection(intersectCompare, [
				subDays(intersectCompare[1], validShortcuts.maxdays),
				intersectCompare[1],
			]);
			result.compareDates.dateRange = intersection100Days as DateRangeDate;
			// console.log(result.compareDates.dateRange, 'installationRange')

			if (intersectCompare > intersection100Days!) {
				if (installationRange !== undefined) {
					// console.log(state, 'installationRange')
					// setValidShortcutsMax(validShortcuts.maxdays)
				}
			}
		}
	} else {
		// result.compareDates = {
		//   ...result.mainDates
		// }
		let defaultRange: DateRangeDate | null = null;
		// console.log('installationRange shorcut&', state.compareDates, validShortcutsCompare, validShortcutsCompare!.options.includes(state.compareDates!.shortcut!))

		let shortcut: Shortcuts | undefined;
		const tempState = { ...state, ...result };
		const shortcutFns = getShortcutFunctions(tempState);

		if (
			state.compareDates &&
			state.compareDates.shortcut &&
			validShortcutsCompare !== undefined &&
			validShortcutsCompare.compareOptions !== undefined &&
			validShortcutsCompare.compareOptions.includes(state.compareDates.shortcut)
		) {
			shortcut = state.compareDates.shortcut;
			if (shortcut !== undefined) {
				let compareRange = getCompareRange(
					validShortcuts,
					shortcut,
					shortcutFns,
					installationRange,
				);
				if (compareRange !== null) {
					// set defaultRange to no enter the next loop
					defaultRange = compareRange.dateRange;
					result.compareDates = compareRange;
				}
			}
		}

		// if it does not work, use the default shortcut for this graph type
		if (!defaultRange) {
			shortcut = validShortcuts!.default as Shortcuts;

			if (shortcut !== undefined) {
				let compareRange = getCompareRange(
					validShortcuts,
					shortcut,
					shortcutFns,
					installationRange,
				);
				if (compareRange !== null) {
					// set defaultRange to no enter the next loop
					defaultRange = compareRange.dateRange;
					result.compareDates = compareRange;
				}
			}
		}

		if (defaultRange === null) {
			if (metering_plan)
				console.error(
					"No default range for this installation",
					metering_plan.data_id,
				);
			else console.error("No default range for this multisite");
		} else {
			result.compareDates = {
				...result.compareDates,
			};
			// console.log('[getDateState] ', result)
		}
	}

	if (result.compareDates.shortcut === undefined) {
		// Check if the range matches a shortcut
		const tempState = { ...state, ...result };
		const shortcutFns = getShortcutFunctions(tempState);
		const options = optionsMask[state.chartType]?.compareOptions || [];
		for (let index = options.length; index >= 0; index--) {
			// iterate in reverse order, larger shortcuts are better
			const option = options[index];
			const checkShorcut = shortcutFns[option];
			const rng = checkShorcut ? checkShorcut() : null;

			// restrict to dates of installation
			const intersect = intersection(rng, installationRange);

			if (sameRange(intersect, result.compareDates.dateRange)) {
				result.compareDates.shortcut = option;
				// console.log('breack incoming')
				break;
			}
		}
	}

	return result;
};

export const MagicButtonReducer = (
	prevState: Readonly<MagicButtonState>,
	action: MagicButtonActions,
): MagicButtonState => {
	// console.log(prevState, action, 'MagicButtonReducer LOCALSTORAGE')

	type valueKey = keyof MagicButtonState;
	// const { enqueueSnackbar } = /* (process.env.NODE_ENV !== 'test') ? useSnackbar() : */ { enqueueSnackbar: (...args: any) => { } }
	let key: valueKey | undefined, value: any, oldvalue: any;
	let newState: Partial<MagicButtonState> = {};
	let finalState: MagicButtonState;
	// const updateMetadata: valueKey[] = ["surface", "hdd", "cdd", "sharedNotes"];
	const prevInstallation = prevState.installation;
	switch (action.type) {
		case MagicActionTypes.Input: // "surface", "hdd", "cdd", "currency", "co2", "people", "maxPower", "maxEnergy" (options)
			const target = action.payload.event.target;
			// const label = action.payload.event.target.labels?.[0].textContent
			key = target.id as valueKey;
			// string to Number
			value = !!target.value ? Number(target.value) : undefined;
			oldvalue = prevState[key];

			if (oldvalue === value) {
				return prevState;
			} else {
				finalState = { ...prevState, [key]: value };
				showDiff(prevState, finalState);
				return finalState;
			}

		case MagicActionTypes.List: // ChartSelector, CategorySelector
			key = action.payload.id as valueKey;
			value = action.payload.value;
			oldvalue = prevState[key];
			if (value === oldvalue) {
				return prevState;
			}

			if (key === "category") {
				value = Number(value);
				const cat = searchLabelById("id", value, prevState.categories);
				// console.log(cat, "cat selected");
				if (cat !== null) {
					newState = {
						categoryLabel: cat.label,
					};
				}
			} else if (key === "chartType") {
				newState = {
					selectedDays: ComparisonTypes.includes(value)
						? SpecialDay.OccupiedDays
						: SpecialDay.OccupiedUnoccupiedDays,

					// we need to reset the selected mainDates/compareDates to the default shortcut
					...resetDates(value as ElectricityTypes),
				};

				newState = {
					...prevState,
					...newState,
					...getMinMaxDates(
						prevState.installationObject || null,
						prevState.site,
						value as ElectricityTypes,
					), // refresh min/max dates based on chartType
					[key]: value,
				};

				if (newState.site) {
					const date_data = getDateState(
						newState as Partial<MagicButtonState> &
							Pick<
								MagicButtonState,
								"chartType" | "site" | "minDate" | "maxDate" | "mainDates"
							>,
					);
					newState = { ...newState, ...date_data };
				}
			}

			// console.log(`[StateTracker] ${key}: ${oldvalue} => ${value}`);

			finalState = { ...prevState, ...newState, [key]: value };
			showDiff(prevState, finalState);
			return finalState;

		case MagicActionTypes.DaysChecked: // DaysSelector
			// State now managed directly in the component
			oldvalue = prevState.selectedDays;
			value = action.payload.value;
			if (isArray(value)) {
				value.sort();
			}
			if (isEqual(value, oldvalue)) {
				return prevState;
			} else {
				// console.log(`[StateTracker] selectedDays: ${oldvalue} => ${value}`);
				finalState = { ...prevState, selectedDays: value };
				showDiff(prevState, finalState);
				return finalState;
			}

		case MagicActionTypes.ToggleButton: // conso, ratio, temperature (options)
			// @ts-ignore
			key = action.payload.event.target.parentElement.id as valueKey;
			// @ts-ignore
			value = action.payload.event.target.value;

			oldvalue = prevState[key];
			// console.log(`[StateTracker] ${key}: ${oldvalue} => ${value}`);
			if (storedUserSettings.includes(key)) {
				localStorage.setItem(key, JSON.stringify(value));
				// const storedValue = localStorage.getItem(key);
			}

			return { ...prevState, [key]: value };
		// TODO : à sauvegarder dans les préférences utilisateurs

		case MagicActionTypes.Switch: // withEvents, withUnoccupation (options)
			key = action.payload.event.target.id as valueKey;
			value = !prevState[key];
			oldvalue = prevState[key];
			// console.log(`[StateTracker] ${key}: ${oldvalue} => ${value}`);
			if (storedUserSettings.includes(key)) {
				localStorage.setItem(key, JSON.stringify(value));
				// const storedValue = localStorage.getItem(key);
			}

			return { ...prevState, [key]: value };
		// TODO : à sauvegarder dans les préférences utilisateurs

		case MagicActionTypes.Tree: // NodeSelector ou InstallationSelector
			const nodeId = action.payload.nodeId;
			const installationId = action.payload.installationId;
			const site = action.payload.Site;
			// console.log('LOCALSTORAGE action', action)
			// Si le node change, ou est rafraichi via updateMetaDataService
			if (
				nodeId &&
				site &&
				(nodeId !== prevState.node ||
					prevState.site?.unique_id !== site.unique_id)
			) {
				newState = {
					node: site?.uuid || "",
				};

				// Update all data relative to the site
				newState = {
					...newState,
					site, // On stocke ici la réponse de /site
					// Lignes ci-dessous à supprimer plus tard
					// currency: site.metadata?.averagekWhCost || null,
					// co2: site.metadata?.averageCO2Impact || null,
					// people: site.metadata?.KPINumber || null,
					sharedNotes: site.metadata.sharedNotes || null,
					// selectedDays: GetOccupiedDays(action.response),
				};
				// TODO move
				localStorage.setItem(
					"site",
					JSON.stringify({ uuid: site.uuid, label: site.label }),
				);
				localStorage.getItem("multisite") &&
					localStorage.removeItem("multisite");

				if (
					prevState.site?.label !== undefined &&
					prevState.site?.label !== "" &&
					newState.site !== undefined
				) {
					// console.log(prevState.site?.label, 'prevState Site')
					// enqueueSnackbar(t`Site ${prevState.site.label } changed by ${newState.site.label}`, { variant: "success" })
				}
			}
			// console.log(action, localStorage.getItem("site"), 'LOCALSTORAGE ! ! ! ')

			if (site.has_data_for_dashboard) {
				const installation = selectInstallation(
					site,
					installationId,
					prevState.installation,
				);

				if (installation !== null) {
					console.debug(installation, "installation. Was:", prevInstallation);
					const installation_data = extractInstallation(
						installation,
						site,
						prevState,
					);

					newState = {
						...prevState,
						...newState,
						...installation_data,
					};

					// reset to default shortcut, if it is not the initial load
					if (prevInstallation !== "") {
						newState = {
							...newState,
							...resetDates(newState.chartType),
						};
					}
					const date_data = getDateState(
						newState as Partial<MagicButtonState> &
							Pick<
								MagicButtonState,
								"chartType" | "site" | "minDate" | "maxDate" | "mainDates"
							>,
					);

					newState = {
						...newState,
						...date_data,
					};
				}
			}

			finalState = { ...prevState, ...newState };
			showDiff(prevState, finalState);
			return finalState;

		case MagicActionTypes.DateRange:
			// TODO we need to update the compareDates.dateRange if compareDates.shortcut needs to reference the previous period
			// console.log('result.mainDates.shortcut DateRange', action.type, prevState.mainDates.dateRange)
			const state: MagicButtonState = { ...prevState, ...action.payload };
			if (!state.mainDates.dateRange) return state;
			else {
				const date_data = getDateState(
					state as Partial<MagicButtonState> &
						Pick<
							MagicButtonState,
							| "chartType"
							| "site"
							| "minDate"
							| "maxDate"
							| "dashboard"
							| "mainDates"
						>,
				);
				finalState = {
					...state,
					...date_data,
				};
				showDiff(prevState, finalState);
				return finalState;
			}

		case MagicActionTypes.sharedNotes:
			oldvalue = prevState.sharedNotes;
			// if (isEqual(oldvalue, value)) {
			if (oldvalue === value) {
				return prevState;
			} else {
				finalState = { ...prevState, sharedNotes: value };
				showDiff(prevState, finalState);
				return finalState;
			}

		case MagicActionTypes.updateStateAction:
			finalState = { ...prevState, ...action.payload.newState };
			showDiff(prevState, finalState);
			// console.log('result.mainDates.shortcut updateStateAction', action.payload, finalState)
			return finalState;
	}
};

const dfs = <Type extends { children?: Type[] }, K extends keyof Type>(
	item: Type,
	idLabel: K,
	idValue: Type[K],
) => {
	if (item[idLabel] === idValue) {
		return item;
	}
	if (item.children) {
		for (const subItem of item.children) {
			const check: any = dfs(subItem, idLabel, idValue);
			if (check) {
				return check;
			}
		}
	}
	return null;
};
export function searchLabelById<Type, K extends keyof Type>(
	idLabel: K,
	idValue: Type[K],
	dict: Type[] | undefined,
): Type | null {
	let result: any = null;
	if (dict !== undefined) {
		for (const item of dict) {
			result = dfs(item as any, idLabel, idValue);
			if (result) break;
		}
	}
	return result;
}

const MagicButtonContext = createContext<MagicButtonContextType>({
	state: initialState(),
	dispatchMagic: () => null,
	dateLabel: (_val: DateKeys) => "",
});

// ----------------------------------------------------------------------

type MagicButtonProviderProps = {
	children: ReactNode;
	chartType?: ElectricityTypes;
};

let firstLoad = true;

export const dateFormatISO = (dt: Date) =>
	formatISO(dt, { representation: "date" });

function MagicButtonProvider({
	children,
	chartType,
}: MagicButtonProviderProps) {
	const location = useLocation();
	const { Site, siteLoading } = useSiteContext();
	const { MultiSites, nodesLoaded } = useNodesContext();
	const {
		benchmarkLoaded,
		minDataDate,
		maxDataDate,
		dateRange,
		maxDateTz,
		maxDaysCount,
		setDateRange,
	} = useBenchmarkContext();
	const { macroCategories } = useSiteContext();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const params = useParams();
	const { currentLang } = useLocales();

	const period = stringToShortcut(searchParams.get("period") || "");
	const dateStartParam = searchParams.get("dateStart");
	const dateEndParam = searchParams.get("dateEnd");
	const comparePeriod = stringToShortcut(
		searchParams.get("comparePeriod") || "",
	);

	const compareDateStart = searchParams.get("compareDateStart");
	const compareDateEnd = searchParams.get("compareDateEnd");

	const parseUrl = (pathname: string, from: "analysis" | "dashboard") => {
		if (from === "analysis") {
			for (const chartTypeString in ElectricityTypes) {
				const urlChartType: ElectricityTypes =
					ElectricityTypes[chartTypeString as keyof typeof ElectricityTypes];
				const pattern = `${PATH_DASHBOARD.analysis.root}/${ChartTypesUrl[urlChartType]}/:node/:installation`;

				const res = matchPath(pattern, pathname);
				// console.log(res, 'respattern')
				if (res !== null) {
					return {
						chartType: urlChartType,
						node: res.params.node,
						installation: res.params.installation,
					};
				}
			}
		}

		if (from === "dashboard") {
			if (params.node) {
				const res = matchPath(
					`${PATH_DASHBOARD.dashboard_with_node}`,
					pathname,
				);
				if (res !== null) {
					return {
						node: res.params.node,
						installation: res.params.installation,
					};
				}
			}
			if (params.customer) {
				const res = matchPath(`${PATH_DASHBOARD.dashboard_customer}`, pathname);
				if (res !== null) {
					return {
						node: res.params.customer,
						params,
					};
				}
			}
		}
	};

	let startingState = cloneDeep(initialState());

	// read chart params, dates from props defined in routing only at first app load
	if (firstLoad) {
		console.debug(
			"first load, Loading chart from url",
			searchParams.toString(),
		);
		// console.log("first load, Loading chart from url", searchParams.toString())
		firstLoad = false;
		if (!params.customer)
			if (chartType !== undefined) {
				startingState.chartType = chartType;
				startingState.selectedDays = ComparisonTypes.includes(chartType)
					? SpecialDay.OccupiedDays
					: SpecialDay.OccupiedUnoccupiedDays;
				// console.log(startingState.selectedDays, "selectedDays startingState")
				// /!\ DO NOT set current node and installation, it will be done once the sites are loaded
				// if (parts.node) startingState.node = parts.node
				// if (parts.installation) startingState.installation = parts.installation

				if (CategoryTypes.includes(chartType)) {
					const val = Number(searchParams.get("category"));
					if (Number.isFinite(val)) {
						startingState.category = val;
					}
				}

				if (SelectedDaysTypes.includes(chartType)) {
					const val = parseSelectedDays(
						searchParams.get("days") || "",
						chartType,
					);
					startingState.selectedDays =
						val !== null ? val : allowedSpecialDays(chartType)[0];
				}
			}

		const defaultDates = resetDates(startingState.chartType);
		if (defaultDates !== null) {
			startingState.mainDates = defaultDates.mainDates;
			startingState.compareDates = defaultDates.compareDates;
		}

		// Shortcut first
		if (period !== undefined) {
			startingState.mainDates.shortcut = period;
			// dateRange from shortcut is computed in getDateState
		} else if (dateStartParam !== null && dateEndParam !== null) {
			// console.log(period, dateStartParam, dateEndParam, 'main period')
			startingState.mainDates.dateRange = [
				parseValidateISO(dateStartParam),
				parseValidateISO(dateEndParam),
			];
			startingState.mainDates.shortcut = undefined;
		}

		if (comparePeriod !== undefined) {
			// startingState.compareDates.dateRange = [null, null]
			startingState.compareDates.shortcut = comparePeriod;
			startingState.compareDates.dateRange = [null, null];
			// console.log(period, startingState.compareDates.shortcut, 'compare period')
			// dateRange from shortcut is computed in getDateState
		} else if (compareDateStart !== null && compareDateEnd !== null) {
			// console.log(period, compareDateStart, compareDateEnd, 'compare period')
			startingState.compareDates.shortcut = undefined;
			startingState.compareDates.dateRange = [
				parseValidateISO(compareDateStart),
				parseValidateISO(compareDateEnd),
			];
		}
	}
	const [state, dispatch] = useReducer(MagicButtonReducer, startingState);
	// const [paramsMainDates, setParamsMainDates] = useState({
	// 	dateRange:
	// 		dateStartParam && dateEndParam
	// 			? [parseValidateISO(dateStartParam), parseValidateISO(dateEndParam)]
	// 			: [null, null],
	// 	shortcut: period,
	// });

	const paramsMainDates = {
		dateRange:
			dateStartParam && dateEndParam
				? [parseValidateISO(dateStartParam), parseValidateISO(dateEndParam)]
				: [null, null],
		shortcut: period,
	};

	useEffect(() => {
		if (!nodesLoaded) return;
		const uuid = isArray(MultiSites) ? "all" : MultiSites?.uuid;
		if (uuid && location.pathname.includes(uuid)) {
			// console.log(MultiSites, MultisitesCount, nodesLoaded, location.pathname, paramsMainDates, 'MultiSites, nodesLoaded, location.pathname')
			// if (state !== startingState)
			if (
				minDataDate &&
				maxDataDate &&
				dateRange &&
				maxDaysCount &&
				macroCategories &&
				MultiSites
			) {
				if (dateRange[0] !== null && dateRange[1] !== null) {
					const checkMainDates =
						paramsMainDates.dateRange[0] !== null &&
						paramsMainDates.dateRange[1] !== null;
					startingState = {
						...startingState,
						dashboard: "multisite" as any,
						mainDates: {
							dateRange:
								checkMainDates || paramsMainDates.shortcut
									? (paramsMainDates.dateRange as DateRangeDate)
									: dateRange,
							shortcut: paramsMainDates.shortcut,
						},
						chartType: ElectricityTypes.Benchmark,
						categories: macroCategories,
						ratio: "without" as any,
						minDate: minDataDate,
						maxDate: maxDataDate,
						maxDaysCount: maxDaysCount,
						node: isArray(MultiSites) ? `all` : MultiSites.uuid,
						nodeLabel: isArray(MultiSites) ? t`All my sites` : MultiSites.label,
						multisiteTz: maxDateTz || "UTC",
					};
					// console.log(startingState, 'startingState')
					dispatch({
						type: MagicActionTypes.updateStateAction,
						payload: { newState: startingState },
					});
					const playload = {
						mainDates: {
							...startingState.mainDates,
						},
						compareDates: {
							...startingState.compareDates,
						},
					};
					dispatch({ type: MagicActionTypes.DateRange, payload: playload });
				}
			}
		}
	}, [MultiSites && benchmarkLoaded && nodesLoaded && location.pathname]);

	useEffect(() => {
		// Watch the SiteContext (which watches the URL) to detect that a new site has been loaded
		if (siteLoading) return;
		if (!Site) return;

		// console.log('useEffect, magic Site', Site)

		// reads the installation uuid from the URL, as default value
		dispatch({
			type: MagicActionTypes.Tree,
			payload: {
				nodeId: Site?.uuid,
				installationId: params.installation || "",
				Site,
			},
		});
	}, [Site, siteLoading]);

	useEffect(() => {
		// Watch the URL to detect a navigation to a new installation
		// console.log('useEffect, magic installation', params.installation)
		if (
			params.installation !== undefined &&
			Site &&
			params.node === state.node &&
			!!params.installation &&
			params.installation !== state.installation
		) {
			dispatch({
				type: MagicActionTypes.Tree,
				payload: { installationId: params.installation, Site },
			});
		}
	}, [params.installation]);

	useEffect(() => {
		// To handle back/forward navigation buttons
		if (chartType && chartType !== state.chartType) {
			dispatch({
				type: MagicActionTypes.List,
				payload: { id: "chartType", value: chartType },
			});
		}
	}, [chartType]);

	useEffect(() => {
		const category = searchParams.get("category") || "";

		const period = stringToShortcut(searchParams.get("period") || "");
		const dateStartParam = searchParams.get("dateStart");
		const dateEndParam = searchParams.get("dateEnd");
		const comparePeriod = stringToShortcut(
			searchParams.get("comparePeriod") || "",
		);
		const compareDateStart = searchParams.get("compareDateStart");
		const compareDateEnd = searchParams.get("compareDateEnd");
		console.debug(
			"first load, Loading chart from url",
			searchParams.toString(),
		);

		if (state.categories && category && category !== `${state.category}`) {
			dispatch({
				type: MagicActionTypes.List,
				payload: { id: "category", value: category },
			});
		}
		const playload = {
			mainDates: {
				...state.mainDates,
			},
			compareDates: {
				...state.compareDates,
			},
		};
		const mainDates = playload.mainDates;
		const compareDates = playload.compareDates;

		if (period !== undefined) {
			mainDates.shortcut = period;
			mainDates.dateRange = [null, null];
			// dateRange from shortcut is computed in getDateState
		} else if (dateStartParam !== null && dateEndParam !== null) {
			mainDates.shortcut = undefined;
			mainDates.dateRange = [
				parseValidateISO(dateStartParam),
				parseValidateISO(dateEndParam),
			];
		}

		if (ComparisonTypes.includes(state.chartType)) {
			if (comparePeriod !== undefined) {
				compareDates.shortcut = comparePeriod;
				compareDates.dateRange = [null, null];
				// dateRange from shortcut is computed in getDateState
			} else if (compareDateStart !== null && compareDateEnd !== null) {
				compareDates.shortcut = undefined;
				compareDates.dateRange = [
					parseValidateISO(compareDateStart),
					parseValidateISO(compareDateEnd),
				];
			}
		}

		const payload = {
			mainDates: state.mainDates,
			compareDates: state.compareDates,
		};
		let modifiedMainDate = false;
		let modifiedCompareDates = false;

		if (!isEqual(state.mainDates, mainDates)) {
			modifiedMainDate = true;
			payload.mainDates = mainDates;
		}
		if (!isEqual(state.compareDates, compareDates)) {
			if (
				comparePeriod &&
				isEqual(comparePeriod, state.compareDates.shortcut)
			) {
				modifiedCompareDates = false;
			} else {
				modifiedCompareDates = true;
				payload.compareDates = compareDates;
			}
		}
		if (modifiedMainDate || modifiedCompareDates) {
			dispatch({ type: MagicActionTypes.DateRange, payload: payload });
		}

		const days = parseSelectedDays(
			searchParams.get("days") || "",
			state.chartType,
		);
		if (days !== null && days !== state.selectedDays) {
			dispatch({
				type: MagicActionTypes.DaysChecked,
				payload: { value: days },
			});
		}
	}, [searchParams]);

	useEffect(() => {
		// Here we watch for updates of the state, and decide to navigate to another page if need be.
		// This is not inside the reducer, since it triggers a bunch of react warnings
		// - si on a un shortcut sélectionné -> URL
		// - sinon, on met dateStart/dateEnd -> URL
		// si chartType est de type comparaison
		//    - si on a un compareShortcut sélectionné -> URL
		//    - sinon, on met compareDateStart/compareDateEnd -> URL
		if (dateRange && state.dashboard === "multisite") {
			if (!isEqual(state.mainDates.dateRange, dateRange)) {
				// console.log(state.mainDates.dateRange, 'state inside MagicButtonProvider useffect ')
				setDateRange(state.mainDates.dateRange);
			}
		}
		const data: { [key: string]: string } = {};
		// const oldvalue = state.mainDates.shortcut !== undefined ? state.mainDates.shortcut : null
		if (state.dashboard === "monosite") {
			if (!state.node) return;
			if (state.mainDates.shortcut) {
				// console.log(state.mainDates.shortcut, 'state.mainDates.shortcut in url')
				data.period = ShortcutString[state.mainDates.shortcut];
			} else if (validRange(state.mainDates.dateRange)) {
				data.dateStart = dateFormatISO(state.mainDates.dateRange[0]);
				data.dateEnd = dateFormatISO(state.mainDates.dateRange[1]);
			}

			if (ComparisonTypes.includes(state.chartType)) {
				if (state.compareDates.shortcut) {
					data.comparePeriod = ShortcutString[state.compareDates.shortcut];
				} else if (validRange(state.compareDates.dateRange)) {
					data.compareDateStart = dateFormatISO(
						state.compareDates.dateRange[0],
					);
					data.compareDateEnd = dateFormatISO(state.compareDates.dateRange[1]);
				}
			}

			if (CategoryTypes.includes(state.chartType)) {
				data.category = `${state.category}`;
			}

			if (SelectedDaysTypes.includes(state.chartType)) {
				data.days = serializeSelectedDays(state.selectedDays);
			}
			const expected_params = new URLSearchParams(data);

			const parts = parseUrl(location.pathname, "analysis");
			if (parts !== undefined) {
				const needsNavigate =
					parts.chartType !== state.chartType ||
					state.installationObject?.data_id !== parts.installation ||
					state.site?.uuid !== parts.node ||
					expected_params.toString() !== searchParams.toString();
				// || expected_params.toString() === ''

				if (needsNavigate) {
					const to = analysisUrl(
						state.chartType,
						state.site?.uuid || "",
						state.installationObject?.data_id || "",
						expected_params,
					);
					if (
						to !== null &&
						location.pathname?.startsWith(PATH_DASHBOARD.analysis.root)
					) {
						if (!isEqual(location, to)) {
							const needsReplace =
								// cases where we want to replace the url:
								// - invalid installation in the site or invalid chartType: pathname has changed
								location.pathname !== to.pathname ||
								// - invalid category in the categories
								(CategoryTypes.includes(state.chartType) &&
									`${state.category}` !== searchParams.get("category")) ||
								// we started from an empty search
								location.search === "";
							// console.log(to.search,searchParams.toString(), 'navi')
							// console.log('should navigate to', to, needsReplace)
							// console.log(expected_params.toString() !== searchParams.toString(), 'paylaod')
							// console.log('useEffect.state', expected_params.toString(), JSON.stringify(state.mainDates))
							navigate(to, { replace: needsReplace });
						}
					}
				}
			}
		} else if (state.dashboard === "multisite") {
			if (!state.node) return;
			if (state.mainDates.shortcut) {
				data.period = ShortcutString[state.mainDates.shortcut];
			} else if (validRange(state.mainDates.dateRange)) {
				data.dateStart = dateFormatISO(state.mainDates.dateRange[0]);
				data.dateEnd = dateFormatISO(state.mainDates.dateRange[1]);
			}

			const expected_params = new URLSearchParams(data);
			const rootPath = location.pathname.split("/");
			// check if expected_params are not empty but not in location.pathname
			if (
				rootPath[1] &&
				(rootPath[1] === "table" ||
					rootPath[1] === "map" ||
					rootPath[1] === "dashboard")
			) {
				// console.log(expected_params.toString(), shortcut, state.mainDates, 'expected_params period')
				// if (expected_params.get('period') && state.mainDates.shortcut && !location.search.includes('?period=')) {
				if (
					expected_params.get("period") &&
					state.mainDates.shortcut &&
					!location.search.includes("?period=")
				) {
					expected_params.set("period", data.period);
					const newUrl = `${
						window.location.pathname
					}?${expected_params.toString()}`;
					// window.history.pushState({}, '', newUrl);
					window.history.replaceState({}, "", newUrl);

					// navigate(newUrl, { replace: true })
				}
				// if (expected_params.get('dateStart') && expected_params.get('dateEnd') && state.mainDates.dateRange && (!location.search.includes('?dateStart=') || !location.search.includes('?dateEnd='))) {
				if (
					expected_params.get("dateStart") &&
					expected_params.get("dateEnd") &&
					(!location.pathname.includes("?dateStart=") ||
						!location.pathname.includes("?dateEnd="))
				) {
					expected_params.set("dateStart", data.dateStart);
					expected_params.set("dateEnd", data.dateEnd);
					const newUrl = `${
						window.location.pathname
					}?${expected_params.toString()}`;
					// window.history.pushState({}, '', newUrl);
					window.history.replaceState({}, "", newUrl);

					// navigate(newUrl, { replace: true })
				}
			}
		}
	}, [state]);

	const dateLabel = (val: DateKeys) => {
		const shortcut = state[val].shortcut;
		if (
			shortcut &&
			state.dashboard !== "multisite"
			// && state.chartType !== ElectricityTypes.Benchmark
		) {
			return shortcutLabel(shortcut);
		} else {
			return (
				labelFromDates(state[val].dateRange, currentLang) || t`Invalid range`
			);
		}
	};

	return (
		<MagicButtonContext.Provider
			value={{
				state,
				dispatchMagic: dispatch,
				dateLabel,
			}}
		>
			{children}
		</MagicButtonContext.Provider>
	);
}

export { MagicButtonContext, MagicButtonProvider };
