import { format } from "date-fns";
import { isEqual } from "lodash";
import {
	type SiteContent,
	type WeekSchedule,
	dayKey,
	dayKeys,
	type siteMetadataObject,
} from "src/@types/SiteContent";
import {
	type DayOfWeek,
	type SelectedDays,
	SpecialDay,
	type WeekDays,
	dayParameters,
} from "src/@types/SpecialDay";
import { installationMetadata } from "src/@types/installation";
import { codeToLocale } from "src/hooks/useLocales";
import { t } from "ttag";
import { capitalizeFirstLetter } from "./text";

// Compute openedDays mask from site data

export function convertToWeekMask(selectedDays: WeekDays, inverse = false) {
	if (inverse) {
		return dayParameters
			.map((value) => (selectedDays.includes(value) ? 0 : 1))
			.join("");
	} else {
		return dayParameters
			.map((value) => (selectedDays.includes(value) ? 1 : 0))
			.join("");
	}
}

export function convertToSelectedDays(weekMask: string, inverse = false) {
	const openedDays: WeekDays = [];
	for (var i = 0; i < Math.min(weekMask.length, 7); i++) {
		if (weekMask[i] === (inverse ? "0" : "1")) {
			openedDays.push((i + 1) as DayOfWeek);
		}
	}
	return openedDays;
}

export function GetOccupiedDaysFromMetadata(
	Metadata: siteMetadataObject | installationMetadata,
	variant: string = "selectedDays",
) {
	let openedDays: WeekDays = [];
	if (
		"buildingSurface" in Metadata ||
		("specificEquipments" in Metadata &&
			Metadata.openingSchedule &&
			Metadata.specificEquipments?.includes("specific_opening_schedule"))
	) {
		if (Metadata && Metadata.openingSchedule) {
			dayKeys.forEach((day, index: number) => {
				if (
					Metadata.openingSchedule![day] &&
					Object.keys(Metadata.openingSchedule![day] || {}).length > 0
				) {
					if (
						Metadata.openingSchedule![day]?.openingTime !== "" &&
						Metadata.openingSchedule![day]?.openingTime !==
							Metadata.openingSchedule![day]?.closingTime
					) {
						const dayofweek = (index + 1) as DayOfWeek;
						openedDays.push(dayofweek);
					}
				}
			});
		}
	}

	if (openedDays.length === 0) {
		openedDays = [1, 2, 3, 4, 5];
	}

	switch (variant) {
		case "selectedDays":
			return openedDays;
		case "occupationMask":
			return convertToWeekMask(openedDays);
		case "unoccupationMask":
			return convertToWeekMask(openedDays, true);
		case "occupationLabel":
			return dayRenderer(openedDays);
		case "unoccupationLabel":
			const closedDays = dayParameters.filter(
				(val) => !openedDays.includes(val),
			);
			return dayRenderer(closedDays);
		default:
			return;
	}
}

export function GetOccupiedDays(
	Site: SiteContent,
	variant: string = "selectedDays",
) {
	if (Site && Site.metadata) {
		return GetOccupiedDaysFromMetadata(Site.metadata, variant);
	}
	return;
}

export function GetOccupiedDaysTimes(Site: SiteContent) {
	const openedDays: {} = {};
	const openedDaysTime: string[] = [];

	if (Site && Site.metadata && Site.metadata.openingSchedule) {
		dayKeys.forEach((day, index: number) => {
			if (
				Site.metadata.openingSchedule![day] &&
				Site.metadata.openingSchedule![day]?.openingTime &&
				Site.metadata.openingSchedule![day]?.closingTime
			) {
				const open: string =
					Site.metadata.openingSchedule![day]!.openingTime +
					" - " +
					Site.metadata.openingSchedule![day]!.closingTime;
				//@ts-ignore
				if (openedDays[open]) {
					//@ts-ignore
					openedDays[open].days.push(index + 1);
				} else {
					//@ts-ignore
					openedDays[open] = {
						openingTime: Site.metadata.openingSchedule![day]!.openingTime,
						closingTime: Site.metadata.openingSchedule![day]!.closingTime,
						days: [index + 1],
					};
				}
			}
		});
	}
	if (Object.entries(openedDays).length > 0) {
		for (const [key, value] of Object.entries(openedDays)) {
			//@ts-ignore
			openedDaysTime.push(dayRenderer(value.days) + ", " + key);
		}
	}

	// Contrary to GetOccupiedDays,
	// no fallback to [1, 2, 3, 4, 5] if the schedule is empty for GetOccupiedDaysTimes

	return openedDaysTime;
}

export const dayRenderer = (selectedDays: SelectedDays) => {
	if (selectedDays === SpecialDay.OccupiedUnoccupiedDays) {
		return t`Occupied/unoccupied days`;
	} else if (selectedDays === SpecialDay.OccupiedDays) {
		return t`Occupied days`;
	} else if (selectedDays === SpecialDay.UnoccupiedDays) {
		return t`Unoccupied days`;
	} else {
		const locale = codeToLocale(
			sessionStorage.getItem("date-fns-locale-code") || "en-GB",
		);
		const dayLabels = [1, 2, 3, 4, 5, 6, 7].map((day) =>
			format(new Date(2018, 0, day), "cccc", { locale }),
		);

		const sorted = selectedDays.sort();
		const differenceAry = sorted.slice(1).map((n, i) => n - sorted[i]);
		const isDifference = differenceAry.every((value) => value === 1);
		if (isDifference && sorted.length > 1) {
			const [min, max] = [sorted[0], sorted[sorted.length - 1]];
			const first_day = dayLabels[min - 1];
			const last_day = dayLabels[max - 1];
			return t`From ${first_day} to ${last_day}`;
		} else {
			return capitalizeFirstLetter(
				sorted.map((value) => dayLabels[value - 1]).join(", "),
			);
		}
	}
};

// export const weekMaskRenderer = (weekMask: string, variant:string) => {
//     dayRenderer(convertToSelectedDays(weekMask));
// }

export const cleanSchedule = (
	metadata: siteMetadataObject,
): WeekSchedule | null => {
	const schedule: WeekSchedule = {
		monday: {},
		tuesday: {},
		wednesday: {},
		thursday: {},
		friday: {},
		saturday: {},
		sunday: {},
	};
	let empty = true;

	if (metadata.openingSchedule) {
		for (const day of dayKeys) {
			// if openingTime or closingTime is missing, ignore both informations and consider the day as closed
			if (
				metadata.openingSchedule[day] &&
				metadata.openingSchedule[day]?.openingTime &&
				metadata.openingSchedule[day]?.closingTime
			) {
				schedule[day] = metadata.openingSchedule[day];
			}
			if (!isEqual(schedule[day], {})) {
				empty = false;
			}
		}
	}

	return empty ? null : schedule;
};
