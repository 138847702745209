import { Params } from "react-router";
import { contactLinkTchat } from "src/components/others/contactLinkTchat";
import { jt } from "ttag";

export const corporateLimit = (userisSmartImpulse?: boolean): number => {
	return userisSmartImpulse ? 800 : 150;
};

export const isAllMySitesFn = (params: Readonly<Params<string>>) =>
	Boolean(params && params.customer && params.customer === "all");

export const alertStringFn = (user: any, isAllMySites: boolean) => {
	const corporateLimitNumber = corporateLimit(user?.isSmartImpulse);
	const contactLink = contactLinkTchat();
	return !user?.isSmartImpulse && isAllMySites
		? jt`Consumption data collection is disabled beyond ${corporateLimitNumber} sites on the “All my sites” dashboard. ${contactLink}`
		: jt`Because of performance, retrieval of consumption data is disabled for this group of sites (more than ${corporateLimitNumber} sites).`;
};
