// ----------------------------------------------------------------------

export const role = [
	"UX Designer",
	"Full Stack Designer",
	"Backend Developer",
	"UX Designer",
	"UX Designer",
	"Project Manager",
	"Leader",
	"Backend Developer",
	"Project Manager",
	"UI Designer",
	"UI/UX Designer",
	"UI/UX Designer",
	"UI Designer",
	"Backend Developer",
	"Backend Developer",
	"Front End Developer",
	"Backend Developer",
	"Full Stack Designer",
	"Full Stack Developer",
	"Backend Developer",
	"UX Designer",
	"UI Designer",
	"Project Manager",
	"UI/UX Designer",
	"UI Designer",
	"Project Manager",
	"Full Stack Developer",
	"Hr Manager",
	"Hr Manager",
	"UI/UX Designer",
	"Project Manager",
	"Full Stack Designer",
	"UI Designer",
	"Leader",
	"Front End Developer",
	"UI/UX Designer",
	"Project Manager",
	"UI/UX Designer",
	"UI Designer",
	"Full Stack Designer",
];
