import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import { ControllerRenderProps } from "react-hook-form";
import { t } from "ttag";

export const PasswordStrengthMeter = (props: {
	field: ControllerRenderProps<any, string>;
	length: number;
}) => {
	const { field, length } = props;
	const minLength = 10;
	const hue = Math.min(length * minLength, 120);
	const theme = useTheme();
	const strengthLabel =
		length < 3
			? t`Very weak`
			: length < 6
				? t`Weak`
				: length < minLength
					? t`Strong`
					: t`Very strong`;

	return (
		<Box sx={{ m: 1 }}>
			<LinearProgress
				variant="determinate"
				value={Math.min((field.value.length * 100) / minLength, 100)}
				sx={{
					bgcolor: theme.palette.grey[500_32],
					"& > :first-child": { bgcolor: `hsl(${hue}, 80%, 40%)` },
				}}
			/>
			<Typography
				sx={{ alignSelf: "flex-end", color: `hsl(${hue}, 80%, 30%)` }}
			>
				{strengthLabel}
			</Typography>
		</Box>
	);
};
