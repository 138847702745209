// ----------------------------------------------------------------------

import { ChartTypesUrl, ElectricityTypes } from "src/@types/charts";

function path(root: string, sublink: string) {
	return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------
export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, "/login"),
	loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
	register: path(ROOTS_AUTH, "/register"),
	registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
	resetPassword: path(ROOTS_AUTH, "/reset-password"),
	verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
	comingSoon: "/coming-soon",
	maintenance: "/maintenance",
	pricing: "/pricing",
	payment: "/payment",
	about: "/about-us",
	contact: "/contact-us",
	faqs: "/faqs",
	page404: "/404",
	page500: "/500",
	components: "/components",
	terms: "/terms",
	privacy: "/privacy",
	empty: "/empty",
};

export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
	// terms: path(ROOTS_DASHBOARD, 'terms'),
	// privacy: path(ROOTS_DASHBOARD, 'privacy'),
	dashboard: path(ROOTS_DASHBOARD, "dashboard/"),
	dashboard_customer: path(ROOTS_DASHBOARD, "dashboard/:customer"),
	dashboard_with_node: path(ROOTS_DASHBOARD, "dashboard/:node"),
	dashboard_table_with_customer: path(ROOTS_DASHBOARD, "table/:customer"),
	dashboard_map_with_customer: path(ROOTS_DASHBOARD, "map/:customer"),
	dashboard_with_node_installation: path(
		ROOTS_DASHBOARD,
		"dashboard/:node/:installation",
	),
	general: {
		// dashboard: path(ROOTS_DASHBOARD, ''),
		// reports: path(ROOTS_DASHBOARD, 'reports'),
		list: path(ROOTS_DASHBOARD, "alerts/"),
		list_with_node: path(ROOTS_DASHBOARD, "alerts/:node"),
		site_info: path(ROOTS_DASHBOARD, "info"),
		site_info_with_node: path(ROOTS_DASHBOARD, "info/:node"),
		site_info_kpi: path(ROOTS_DASHBOARD, "info/:node/kpi/:indicator?"),
		installation_info: path(ROOTS_DASHBOARD, "info/:node/:installation"),
		actions: path(ROOTS_DASHBOARD, "actions"),
		// users: path(ROOTS_DASHBOARD, 'users'),
		projects: path(ROOTS_DASHBOARD, "projects"),
		issues: path(ROOTS_DASHBOARD, "issues"),
		// export: path(ROOTS_DASHBOARD, 'export'),
		// api: path(ROOTS_DASHBOARD, 'api'),
		meteringplan: path(ROOTS_DASHBOARD, "meteringplan"),
		terms: path(ROOTS_DASHBOARD, "dashboard/terms"),
		privacy: path(ROOTS_DASHBOARD, "dashboard/privacy"),
	},
	settings: path(ROOTS_DASHBOARD, "settings"),
	settings_password: path(ROOTS_DASHBOARD, "settings/password"),
	analysis: {
		root: path(ROOTS_DASHBOARD, `analysis`),
		// root_with_node: path(ROOTS_DASHBOARD, `analysis/:node/:installation`),
		chart: path(
			ROOTS_DASHBOARD,
			`analysis/${
				ChartTypesUrl[ElectricityTypes.EnergyMonth]
			}/:node/:installation`,
		),
		// chart: path(ROOTS_DASHBOARD, `analysis/:chartType/:node_uuid/:installation_uuid/?dateStart=:dateRangeStart&dateEnd=:dateRangeEnd` ),
		// chart: path(ROOTS_DASHBOARD, `analysis/${ChartTypesUrl[state.chartType]}/${state.node}/${state.installation}?dateStart=${dateRange[0] && dateRange[1] ? formatISO(dateRange[0])+'&dateEnd='+formatISO(dateRange[1]): null}` ),
	},
	mail: {
		root: path(ROOTS_DASHBOARD, "/mail"),
		all: path(ROOTS_DASHBOARD, "/mail/all"),
	},
	chat: {
		root: path(ROOTS_DASHBOARD, "/chat"),
		new: path(ROOTS_DASHBOARD, "/chat/new"),
		conversation: path(ROOTS_DASHBOARD, "/chat/:conversationKey"),
	},
	calendar: path(ROOTS_DASHBOARD, "/calendar"),
	kanban: path(ROOTS_DASHBOARD, "/kanban"),
	alerts: {
		// list: path(ROOTS_DASHBOARD, 'alerts'),
		list: path(ROOTS_DASHBOARD, "alerts/"),
		list_with_node: path(ROOTS_DASHBOARD, "alerts/:node"),
		list_with_customer: path(ROOTS_DASHBOARD, "alerts/table/:customer"),
		notifications: path(ROOTS_DASHBOARD, "alerts/notifications"),
		notifications_with_node: path(
			ROOTS_DASHBOARD,
			"alerts/notifications/:node",
		),
		notification: path(ROOTS_DASHBOARD, "alerts/notifications/:node/:notif_id"),
		notifications_with_customer: path(
			ROOTS_DASHBOARD,
			"alerts/notifications/table/:customer",
		),
		details: path(ROOTS_DASHBOARD, "alerts/:node/:alert_id"),
		details_notification: path(
			ROOTS_DASHBOARD,
			"alerts/:node/:alert_id/notifications/:notif_id",
		),
		create: path(ROOTS_DASHBOARD, "alerts/create"),
		edit: path(ROOTS_DASHBOARD, "alerts/reece-chung"),
	},

	reports: {
		list: path(ROOTS_DASHBOARD, "reports"),
		notifications: path(ROOTS_DASHBOARD, "reports/notifications"),
		create: path(ROOTS_DASHBOARD, "reports/create"),
		edit: path(ROOTS_DASHBOARD, "reports/reece-chung"),
	},
	export: {
		export: path(ROOTS_DASHBOARD, "export"),
		api: path(ROOTS_DASHBOARD, "export/api"),
	},
	users: {
		list: path(ROOTS_DASHBOARD, "users"),
		// profile: path(ROOTS_DASHBOARD, 'users/profile'),
		// cards: path(ROOTS_DASHBOARD, 'users/cards'),
		// list: path(ROOTS_DASHBOARD, 'users/list'),
		newUser: path(ROOTS_DASHBOARD, "users/create"),
		editById: path(ROOTS_DASHBOARD, `users/reece-chung`),
		// account: path(ROOTS_DASHBOARD, 'settings'),
	},
	eCommerce: {
		root: path(ROOTS_DASHBOARD, "/e-commerce"),
		shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
		product: path(ROOTS_DASHBOARD, "/e-commerce/product/:name"),
		productById: path(
			ROOTS_DASHBOARD,
			"/e-commerce/product/nike-air-force-1-ndestrukt",
		),
		list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
		newProduct: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
		editById: path(
			ROOTS_DASHBOARD,
			"/e-commerce/product/nike-blazer-low-77-vintage/edit",
		),
		checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
		invoice: path(ROOTS_DASHBOARD, "/e-commerce/invoice"),
	},
	blog: {
		root: path(ROOTS_DASHBOARD, "/blog"),
		posts: path(ROOTS_DASHBOARD, "/blog/posts"),
		post: path(ROOTS_DASHBOARD, "/blog/post/:title"),
		postById: path(
			ROOTS_DASHBOARD,
			"/blog/post/apply-these-7-secret-techniques-to-improve-event",
		),
		newPost: path(ROOTS_DASHBOARD, "/blog/new-post"),
	},
};

export const PATH_DOCS = "#";
