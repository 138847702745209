import { utcToZonedTime } from "date-fns-tz";
import { max, min } from "lodash";
import type { Category } from "./magic";

export type DegreeDayReferences = {
	heating?: number;
	cooling?: number;
	method?: string;
};

export type Event = {
	focusedInput?: boolean | null;
	startDate?: Date | null;
	endDate?: Date | null;
	value?: string;
	label?: string;
	equipment?: string;
	public?: boolean;
	description?: string;
};

type baseEquipment = {
	equipment?: string;
	description?: string;
	number?: number;
	power?: number;
	schedule?: string;
	internalNotes?: string;
	idForInstall?: number;
};

export type UPSEquipment = baseEquipment & {
	charge?: string;
};

export type HVACEquipment = baseEquipment & {
	withVSD?: boolean;
};

type regulation =
	| "no_regulation"
	| "clock"
	| "bms"
	| "presence_detector"
	| "lightness_detector";

export type lightingEquipment = baseEquipment & {
	regulation?: regulation;
};

export type specificEquipment =
	| "capacitor_bank"
	| "power_generation"
	| "ups"
	| "datacenter"
	| "indoor_car_park"
	| "restaurant"
	| "submetering"
	| "specific_opening_schedule";

export type day = {
	openingTime?: string;
	closingTime?: string;
};

export const dayKeys = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

export type dayKey = typeof dayKeys;
export type WeekScheduleKey = dayKey[number];
export type WeekSchedule = { [key in WeekScheduleKey]?: day };

type smartX = {
	name?: string;
	usage?: string;
	isSmall?: boolean;
	idForInstall?: number;
};

export type installationMetadata = {
	surface?: number;
	degree_day_references?: DegreeDayReferences;
	equipmentsUPS?: UPSEquipment[];
	equipmentsOther?: baseEquipment[];
	title?: string;
	internal_reference?: string;
	installationType?: string;
	installationDescription?: string;
	specificEquipments?: specificEquipment[];
	subMetering?: smartX[];
	equipmentsHVAC?: HVACEquipment[];
	equipmentsLighting?: lightingEquipment[];
	equipmentsIT?: baseEquipment[];
	datacenterDescription?: string;
	parkingDescription?: string;
	restaurantDescription?: string;
	notBenchmarkAble?: boolean;
	smartX?: smartX[];
	events?: Event[];
	sharedNotes?: string;
	openingSchedule?: WeekSchedule;
	nextID?: number;
	technicalInformation?: object;
};

export enum intervalLengthEnum {
	len1sec = "len1sec",
	len30sec = "len30sec",
	len1min = "len1min",
	len5min = "len5min",
	len10min = "len10min",
	len15min = "len15min",
	len20min = "len20min",
	len30min = "len30min",
	len1hour = "len1hour",
	len1day = "len1day",
	len1week = "len1week",
	len1month = "len1month",
	len1quarter = "len1quarter",
	len1semester = "len1semester",
	len1year = "len1year",
}

export type meteringplanBenchmark = {
	data_id: string;
	label: string;
	date_begin_customer: string | null;
	date_end_customer: string | null;
	date_end_temporary: Date | string | any;
	current_data_start: string | null;
	current_data_end: string | null;
	surface: number | null;
	site_uuid: string;
	site_label: string;
	timezone: string;
};

export type meteringplanBaseObject = {
	// id:number,
	data_id: string;
	label: string;
	date_begin_customer: string | null;
	date_end_customer: string | null;
	measure_type:
		| "MAIN"
		| "MAIN_SHARED_AREA"
		| "MAIN_PRIVATE_AREA"
		| "CAPACITOR"
		| "POWER"
		| "SPECIFIC_MONO_EQUIPMENT"
		| "SPECIFIC_MULTI_EQUIPMENTS"
		| "COMPOSITE"
		| "COMPOSITE_FULL_SITE_BREAKDOWN"
		| "COMPOSITE_FULL_SITE_TGBT"
		| "SPECIFIC_BUILDING"
		| "RIE"
		| "PARKING"
		| "DATACENTER"
		| "SPECIFIC_ZONE"
		| "UNKNOWN";
	metadata: installationMetadata;
	public: boolean;
	has_users: boolean;
	is_breakdown: boolean;
	has_children_with_breakdown: boolean;
	categories: Category[];
	current_data_start: string | null;
	current_data_end: string | null;
	loadcurve_start: string | null;
	loadcurve_end: string | null;
	type?: "physical" | "total_by_end_use" | "virtual" | "total_by_zone";
	interval_length: keyof typeof intervalLengthEnum;
	date_end_temporary?: Date | string | any;
	user_access: boolean;
	has_children_with_user_access: boolean;
	children?: meteringplanObject[];
	included_installations?: meteringplanBaseObject["data_id"][];
	can_access_alerts: boolean;
	has_children_with_alerts: boolean;
	can_view_metadata: boolean;
	has_children_view_metadata: boolean;
	can_edit_metadata: boolean;
	has_children_edit_metadata: boolean;
	has_data_for_dashboard: boolean;
	has_children_with_data: boolean;
};

export type meteringplanObject = meteringplanBaseObject & {
	hardware_reference?: number | string | null;
	hardware_type?: "smartanalyzer" | "smartx";
	multisensor_kit?: string;
};

export type meteringplanVirtualObject = meteringplanBaseObject & {};

export const zonedDateInThePast = (
	val: string | null,
	timezone: string,
	minDate?: Date,
) => {
	// convert to "naive" with site timezone info, and cap to a past date
	if (val !== null) {
		const dt = utcToZonedTime(val, timezone);
		const now = utcToZonedTime(Date.now(), timezone);
		return min([dt, minDate ? minDate : now]);
	}
	return null;
};

export const installationZonedDates = (
	metering_plan: meteringplanBenchmark | meteringplanBaseObject,
	timezone: string,
	minDate?: Date,
) => {
	const startArray = [
		metering_plan.current_data_start,
		metering_plan.date_begin_customer,
	];
	const start =
		max(
			startArray
				.map((val) => zonedDateInThePast(val, timezone))
				.filter((val) => !!val),
		) || null;
	const endArray = [
		metering_plan.current_data_end,
		metering_plan.date_end_customer,
	]; // date_end_customer is date_end_temporary when it exists
	const end =
		min(
			endArray
				.map((val) => zonedDateInThePast(val, timezone, minDate))
				.filter((val) => !!val),
		) || null;
	return { start, end };
};

export const loadcurveZonedDates = (
	metering_plan: meteringplanBaseObject,
	timezone: string,
) => {
	const startArray = [metering_plan.loadcurve_start]; // the backend already does a max with date_begin_customer
	const start =
		max(
			startArray
				.map((val) => zonedDateInThePast(val, timezone))
				.filter((val) => !!val),
		) || null;
	const endArray = [metering_plan.loadcurve_end]; // the backend already does a min with date_end_customer (NOT date_end_temporary)
	const end =
		min(
			endArray
				.map((val) => zonedDateInThePast(val, timezone))
				.filter((val) => !!val),
		) || null;
	return { start, end };
};
