import {
	addDays,
	differenceInCalendarDays,
	endOfDay,
	endOfMonth,
	endOfQuarter,
	endOfWeek,
	endOfYear,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
	startOfYear,
	subDays,
	subMonths,
	subQuarters,
	subWeeks,
	subYears,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import type { DateRangeDate } from "src/@types/magic";
// 'Today','Yesterday','This week','Last week','Last 2 weeks','This month', 'Last month', 'Last 30 days', 'This quarter','Last quarter','This year', 'Last year', 'Last 12 months','All History'

export const shortcutFunctions = (tz?: string) => {
	const getRef = () => utcToZonedTime(new Date(), tz || "UTC");

	const arrayEndOrRef = (dateArray?: DateRangeDate): Date => {
		let ref;
		if (dateArray && dateArray[1]) {
			ref = dateArray[1];
		} else {
			ref = getRef();
		}
		return ref;
	};

	const getTodayInMonth = (): DateRangeDate => {
		const ref = getRef();
		return [startOfDay(ref), ref];
	};

	const getYesterdayInMonth = (): DateRangeDate => {
		const ref = subDays(getRef(), 1);
		return [startOfDay(ref), endOfDay(ref)];
	};

	const getLastDay = (dateArray: DateRangeDate): DateRangeDate => {
		const ref = arrayEndOrRef(dateArray);
		return [startOfDay(ref), ref];
	};

	const getThisMonth = (dateArray?: DateRangeDate): DateRangeDate => {
		const ref = arrayEndOrRef(dateArray);
		return [startOfMonth(ref), ref];
	};

	const getLastMonth = (): DateRangeDate => {
		const ref = subMonths(getRef(), 1);
		return [startOfMonth(ref), endOfMonth(ref)];
	};

	const getThisWeek = (): DateRangeDate => {
		const ref = getRef();
		return [startOfWeek(ref, { weekStartsOn: 1 }), ref];
	};

	const getLastWeek = (): DateRangeDate => {
		const ref = subWeeks(getRef(), 1);
		const start = startOfWeek(ref, { weekStartsOn: 1 });
		const end = endOfWeek(ref, { weekStartsOn: 1 });
		return [start, end];
	};

	const getFourLastWeeks = (dateArray: DateRangeDate): DateRangeDate => {
		const ref = arrayEndOrRef(dateArray);
		return [subWeeks(startOfWeek(ref, { weekStartsOn: 1 }), 3), ref];
	};

	const getLast30days = (dateArray?: DateRangeDate): DateRangeDate => {
		const ref = arrayEndOrRef(dateArray);
		return [startOfDay(subDays(ref, 30)), ref];
	};

	const getThisYear = (): DateRangeDate => {
		const ref = getRef();
		return [startOfDay(startOfYear(ref)), ref];
	};

	const getLastYear = (): DateRangeDate => {
		const ref = subYears(getRef(), 1);
		return [startOfYear(ref), endOfYear(ref)];
	};

	const getLast12Months = (dateArray: DateRangeDate): DateRangeDate => {
		const ref = arrayEndOrRef(dateArray);
		return [addDays(startOfDay(subMonths(ref, 12)), 1), ref];
	};

	const getThisQuarter = (): DateRangeDate => {
		const ref = getRef();
		return [startOfQuarter(ref), ref];
	};

	const getLastQuarter = (): DateRangeDate => {
		const ref = subQuarters(getRef(), 1);
		return [startOfQuarter(ref), endOfQuarter(ref)];
	};

	const getSamePeriodLastYear = (dateArray: DateRangeDate): DateRangeDate => {
		if (dateArray[0] && dateArray[1]) {
			return [subYears(dateArray[0], 1), subYears(dateArray[1], 1)];
		} else return dateArray;
	};

	const getPreviousPeriod = (dateArray: DateRangeDate): DateRangeDate => {
		if (dateArray[0] && dateArray[1]) {
			// add 1 day to date end to get a difference inclunding the end dat
			const offset = differenceInCalendarDays(
				addDays(dateArray[1], 1),
				dateArray[0],
			);
			return [subDays(dateArray[0], offset), subDays(dateArray[1], offset)];
		} else return dateArray;
	};

	return {
		getTodayInMonth,
		getYesterdayInMonth,
		getLastDay,
		getThisMonth,
		getLastMonth,
		getThisWeek,
		getLastWeek,
		getFourLastWeeks,
		getLast30days,
		getThisYear,
		getLastYear,
		getLast12Months,
		getThisQuarter,
		getLastQuarter,
		getSamePeriodLastYear,
		getPreviousPeriod,
	};
};
