// ----------------------------------------------------------------------

export const boolean = [
	true,
	true,
	true,
	false,
	false,
	true,
	false,
	false,
	false,
	false,
	true,
	true,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	false,
	true,
	false,
	false,
	true,
	true,
	true,
	false,
	false,
	true,
	true,
	false,
	true,
	false,
	true,
	true,
	true,
	false,
	true,
	false,
	false,
];
