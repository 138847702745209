import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
// @mui
import {
	Alert,
	Button,
	IconButton,
	InputAdornment,
	Link,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { Link as RouterLink, redirect, useLocation } from "react-router-dom";
import useFeedbackContext from "src/hooks/useFeedbackContext";
import { jt, t } from "ttag";
import * as Yup from "yup";
import {
	FormProvider,
	RHFTextField /* RHFCheckbox */,
} from "../../../components/hook-form";
// components
import Iconify from "../../../components/others/Iconify";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// routes
import { PATH_AUTH } from "../../../routes/paths";

// ----------------------------------------------------------------------

type FormValuesProps = {
	email: string;
	password: string;
	remember: boolean;
	afterSubmit?: string;
};

type FormValuesPropsSSO = {
	email: string;
};

interface LoginFormProps {
	onFormTypeChange: (newValue: boolean) => void;
}

export default function LoginForm({ onFormTypeChange }: LoginFormProps) {
	const { loginRest, loginSSO, forgotEmail } = useAuth();
	const isMountedRef = useIsMountedRef();
	const location = useLocation();

	const [showPassword, setShowPassword] = useState(false);
	const [emailForm, setEmailForm] = useState(true);
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [version, setVersion] = useState("");

	const searchErrorParam = new URLSearchParams(window.location.search);
	const errorParam = searchErrorParam.get("error");

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email(t`The input email must be a valid email.`)
			.required(t`Email is required`),
		// password: Yup.string().required(t`Password is required`),
	});

	const { setOpenContact } = useFeedbackContext();

	useEffect(() => {
		onFormTypeChange(emailForm);
	}, [emailForm]);

	useEffect(() => {
		if (errorParam !== null) {
			if (errorParam === "absent") {
				setErrorMessage(t`Unable to load user information.`);
			} else if (errorParam === "expired") {
				setErrorMessage(t`Your account has expired.`);
			} else if (errorParam === "exception") {
				setErrorMessage(t`Something went wrong.`);
			}
		}
		const url = window.location.href;
		const versions = ["/beta", "/preprod"];
		const currentVersion = versions.find((v) => url.includes(`${v}`)) || "";
		setVersion(currentVersion);
	}, []);

	const defaultValues = {
		email: "",
		password: "",
		remember: true,
	};

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		setError,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = methods;

	const onSubmit = async (data: FormValuesProps) => {
		try {
			// @ts-ignore
			await loginRest(data.email, data.password, data.remember);
		} catch (error) {
			console.error(error);
			if (isMountedRef.current) {
				setError("afterSubmit", { type: "custom", message: error.message });
			}
		}
	};

	const onSubmitSSO = async (data: FormValuesPropsSSO) => {
		try {
			// @ts-ignore
			const response = await loginSSO(data.email);
			const parsedResponse = JSON.parse(response);

			if (parsedResponse.action === "password") {
				setEmailForm(false);
				setEmail(data.email);
				forgotEmail(data.email);
			}
			if (parsedResponse.redirect_url) {
				window.location.href =
					parsedResponse.redirect_url + "&next=" + version + location.pathname;
			}

			return parsedResponse;
		} catch (error) {
			console.error(error);
		}
	};

	const contactUsLink = (
		<Link
			key="contact-us"
			component="button"
			variant="body2"
			sx={{ verticalAlign: "baseline" }}
		>
			<Typography variant="body2" onClick={() => setOpenContact(true)}>
				{t`contact us`}
			</Typography>
		</Link>
	);
	return (
		<FormProvider
			methods={methods}
			onSubmit={!emailForm ? handleSubmit(onSubmit) : handleSubmit(onSubmitSSO)}
		>
			<Stack spacing={3}>
				{!!errors.afterSubmit ? (
					<>
						<Alert severity="error">{errors.afterSubmit.message}</Alert>
						{/* <Alert severity="info" sx={{ mb: 3 }}>
                {t`Use email or username:`} <strong>exemple@smart-impulse.com</strong>|<strong>Charles</strong> / password :<strong> password1234</strong>. {t`If you need more info, contact us at ${link}.`}
              </Alert> */}
					</>
				) : errorMessage ? (
					<>
						<Alert severity="error">{errorMessage}</Alert>
					</>
				) : null}

				{errors.afterSubmit || errorMessage ? (
					<Alert severity="info">
						{jt`If you need more information, do not hesitate to ${contactUsLink}`}
					</Alert>
				) : null}

				{/* {emailForm ? <RHFTextField name="email" label={t`Email address`} autoComplete="username" sx={{ my: 2 }}/>: <Typography>{email}</Typography> } */}
				{emailForm ? (
					<RHFTextField
						name="email"
						label={t`Email address`}
						autoComplete="username"
						sx={{ my: 2 }}
					/>
				) : (
					<Link
						onClick={() => {
							setEmailForm(true);
						}}
						variant="subtitle2"
					>
						{email}
					</Link>
				)}

				{!emailForm && (
					<>
						<TextField
							name="email"
							value={email}
							label={t`Email address`}
							autoComplete="username"
							sx={{
								position: "absolute",
								width: 0,
								height: 0,
								padding: 0,
								border: "none",
								margin: 0,
								overflow: "hidden",
								opacity: 0,
							}}
						/>
						<RHFTextField
							name="password"
							label={t`Password`}
							autoFocus
							type={showPassword ? "text" : "password"}
							autoComplete="current-password"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setShowPassword(!showPassword)}
											edge="end"
										>
											<Iconify
												icon={
													showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
												}
											/>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</>
				)}
			</Stack>

			{!emailForm && (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{ my: 2 }}
				>
					{/* <RHFCheckbox name="remember" label={t`Remember me`} title={t`Remain connected when reopening the web browser`} /> */}
					<Link
						component={RouterLink}
						variant="subtitle2"
						to={PATH_AUTH.resetPassword}
					>
						{t`Forgot password?`}
					</Link>
				</Stack>
			)}

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				loading={isSubmitting}
				sx={{ marginTop: "16px" }}
			>
				{t`Login`}
			</LoadingButton>
			{!emailForm && (
				<Button
					fullWidth
					sx={{ mt: 2 }}
					onClick={() => {
						setEmailForm(true);
					}}
				>
					{t`Back`}
				</Button>
			)}
		</FormProvider>
	);
}
