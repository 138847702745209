import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Grid, Link, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { PasswordStrengthMeter as PasswordStrengthMeterJSX } from "src/components/animate/PasswordStrengthMeter";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH, PATH_PAGE } from "src/routes/paths";
import { changePassword } from "src/services/service";
import { t } from "ttag";
import * as Yup from "yup";
import { FormProvider, RHFTextField } from "../../../../components/hook-form";

type FormValuesProps = {
	currentPassword: string;
	newPassword: string;
};

export default function AccountChangePassword() {
	const { enqueueSnackbar } = useSnackbar();
	const [PasswordStrengthMeter, setPasswordStrengthMeter] =
		useState<JSX.Element | null>(null);
	const ChangePassWordSchema = Yup.object().shape({
		currentPassword: Yup.string().required(t`Old Password is required`),
		newPassword: Yup.string()
			.min(6, t`Password must be at least 6 characters`)
			.required(t`New Password is required`),
	});

	const defaultValues = {
		currentPassword: "",
		newPassword: "",
	};

	const methods = useForm({
		resolver: yupResolver(ChangePassWordSchema),
		defaultValues,
	});

	const {
		reset,
		handleSubmit,
		formState: { isSubmitting, isDirty, isValid },
	} = methods;

	const { doLogout, logout } = useAuth();
	const navigate = useNavigate();

	const handleForgotPassword = async () => {
		try {
			await doLogout();
			navigate(PATH_AUTH.resetPassword, { replace: true });
			await logout();
		} catch (_error) {
			navigate(PATH_PAGE.page500, { replace: true });
			enqueueSnackbar(t`Unable to logout!`, { variant: "error" });
		}
	};

	const onSubmit = async (_data: FormValuesProps) => {
		try {
			const response = await changePassword({
				old_password: _data.currentPassword,
				new_password: _data.newPassword,
			});
			if (response.status === 200) {
				enqueueSnackbar(response.message || t`Password updated successfully!`, {
					variant: "success",
				});
				reset();
				setPasswordStrengthMeter(null);
			} else {
				let errorMessage =
					response.errors?.detail || t`Error updating password.`;
				if (response.errors?.old_password) {
					errorMessage = response.errors.old_password[0];
				} else if (response.errors?.new_password2) {
					errorMessage = response.errors.new_password2[0];
				} else if (response.errors?.message) {
					errorMessage = response.errors?.message;
				}
				enqueueSnackbar(errorMessage, {
					variant: "error",
				});
				reset();
				setPasswordStrengthMeter(null);
			}
		} catch (error) {
			console.error(t`Error in password change:`, error);
			enqueueSnackbar(
				t`An unexpected error occurred. Please try again later.`,
				{
					variant: "error",
				},
			);
			setPasswordStrengthMeter(null);
		}
	};

	const callbackPassword = (field: ControllerRenderProps<any, string>) => {
		const length = field.value.length as number;
		if (
			length > 0 &&
			setPasswordStrengthMeter &&
			field.name === "newPassword"
		) {
			setPasswordStrengthMeter(
				<PasswordStrengthMeterJSX field={field} length={length} />,
			);
		} else if (
			length === 0 &&
			setPasswordStrengthMeter &&
			field.name === "newPassword"
		) {
			setPasswordStrengthMeter(null);
		}
	};

	return (
		<Card sx={{ p: 3, minHeight: 500 }}>
			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3} alignItems="flex-start">
					<Grid item xs={12} sm={6}>
						<RHFTextField
							fullWidth
							name="currentPassword"
							type="password"
							label={t`Current password`}
							requiredAsterix={isDirty}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<RHFTextField
							fullWidth
							name="newPassword"
							type="password"
							label={t`New password`}
							requiredAsterix={isDirty}
							callbackPassword={callbackPassword}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={3} wrap={"wrap-reverse"}>
					<Grid item xs={12} sm={6}>
						<Stack spacing={3} alignItems="flex-start">
							<Box sx={{ pt: 1 }}>
								<Link
									component={RouterLink}
									variant="subtitle2"
									onClick={handleForgotPassword}
									to={PATH_AUTH.resetPassword}
								>
									{t`Forgot password?`}
								</Link>
							</Box>
							<Box>
								<LoadingButton
									type="submit"
									variant="contained"
									loading={isSubmitting}
									disabled={!isDirty || Boolean(isDirty && !isValid)}
								>
									{t`Save changes`}
								</LoadingButton>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box sx={{ mt: 1 }}>{PasswordStrengthMeter}</Box>
					</Grid>
				</Grid>
			</FormProvider>
		</Card>
	);
}
