// TODO
// Filtrer shortcuts en fonction de l'url

import { ListItemButton, styled } from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { format } from "date-fns";
import type * as React from "react";
import {
	type Path,
	NavLink as RouterLink,
	useLocation,
} from "react-router-dom";
import { ElectricityTypes } from "src/@types/charts";
import type { DateKeys, MagicButtonState } from "src/@types/magic";
// import { useSnackbar } from 'notistack';
import type { DateRangeDate } from "src/contexts/dateRangeContext";
import useDateRange from "src/hooks/useDateRange";
import useLocales, { type Lang } from "src/hooks/useLocales";
import useMagicButtonContext from "src/hooks/useMagicButtonContext";
import { intersection } from "src/utils/dateRange";
import { t } from "ttag";
import { buildPath } from "../dashboard/MagicButton/layout/listComponents";
import Scrollbar from "../others/Scrollbar";
import { shortcutFunctions } from "./shortcutOptions";

interface ShorcutProps {
	isMobile?: boolean;
	setMobile?: React.Dispatch<React.SetStateAction<boolean>>;
	// selectedIndex: number | undefined,
	// setSelectedIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
	handleClose?: (value?: any) => void;
	keyState: DateKeys;
}

export enum Shortcuts {
	Today = 1, // Start at 1 to avoid falsey comparison
	Yesterday = 2,
	LastDay = 3,
	ThisWeek = 4,
	LastWeek = 5,
	Last4Weeks = 6,
	ThisMonth = 7,
	LastMonth = 8,
	Last30Days = 9,
	ThisQuarter = 10,
	LastQuarter = 11,
	ThisYear = 12,
	LastYear = 13,
	Last12Months = 14,
	AllHistory = 15,
	SamePeriodLastYear = 16,
	PreviousPeriod = 17,
}

export const ShortcutString: { [key in Shortcuts]: string } = {
	[Shortcuts.Today]: "today",
	[Shortcuts.Yesterday]: "yesterday",
	[Shortcuts.LastDay]: "lastday",
	[Shortcuts.ThisWeek]: "thisweek",
	[Shortcuts.LastWeek]: "lastweek",
	[Shortcuts.Last4Weeks]: "4weeks",
	[Shortcuts.ThisMonth]: "thismonth",
	[Shortcuts.LastMonth]: "lastmonth",
	[Shortcuts.Last30Days]: "30days",
	[Shortcuts.ThisQuarter]: "thisquarter",
	[Shortcuts.LastQuarter]: "lastquarter",
	[Shortcuts.ThisYear]: "thisyear",
	[Shortcuts.LastYear]: "lastyear",
	[Shortcuts.Last12Months]: "12months",
	[Shortcuts.AllHistory]: "all",
	[Shortcuts.SamePeriodLastYear]: "sameperiodlastyear",
	[Shortcuts.PreviousPeriod]: "previousperiod",
};

export const stringToShortcut = (val: string): Shortcuts | undefined => {
	switch (val) {
		case ShortcutString[Shortcuts.Today]:
			return Shortcuts.Today;
		case ShortcutString[Shortcuts.Yesterday]:
			return Shortcuts.Yesterday;
		case ShortcutString[Shortcuts.LastDay]:
			return Shortcuts.LastDay;
		case ShortcutString[Shortcuts.ThisWeek]:
			return Shortcuts.ThisWeek;
		case ShortcutString[Shortcuts.LastWeek]:
			return Shortcuts.LastWeek;
		case ShortcutString[Shortcuts.Last4Weeks]:
			return Shortcuts.Last4Weeks;
		case ShortcutString[Shortcuts.ThisMonth]:
			return Shortcuts.ThisMonth;
		case ShortcutString[Shortcuts.LastMonth]:
			return Shortcuts.LastMonth;
		case ShortcutString[Shortcuts.Last30Days]:
			return Shortcuts.Last30Days;
		case ShortcutString[Shortcuts.ThisQuarter]:
			return Shortcuts.ThisQuarter;
		case ShortcutString[Shortcuts.LastQuarter]:
			return Shortcuts.LastQuarter;
		case ShortcutString[Shortcuts.ThisYear]:
			return Shortcuts.ThisYear;
		case ShortcutString[Shortcuts.LastYear]:
			return Shortcuts.LastYear;
		case ShortcutString[Shortcuts.Last12Months]:
			return Shortcuts.Last12Months;
		case ShortcutString[Shortcuts.AllHistory]:
			return Shortcuts.AllHistory;
		case ShortcutString[Shortcuts.SamePeriodLastYear]:
			return Shortcuts.SamePeriodLastYear;
		case ShortcutString[Shortcuts.PreviousPeriod]:
			return Shortcuts.PreviousPeriod;
	}
};

export type DefaultShortcuts =
	| Shortcuts.LastDay
	| Shortcuts.Last4Weeks
	| Shortcuts.Last12Months
	| Shortcuts.AllHistory
	| Shortcuts.SamePeriodLastYear
	| Shortcuts.PreviousPeriod;

export type Options = {
	options: Shortcuts[]; // liste des shortcuts à afficher dans le dateSelector en fonction du type de chart
	default: DefaultShortcuts; // shortcut sélectionné par défaut pour date quand on change de type de graph
	compareOptions?: Shortcuts[]; // liste des shortcuts à afficher dans le dateReferenceSelector
	compareSameNumberOfDays?: boolean; // Si true, dateRef doit avoir le même nombre de jours que date
	compareDefault?: DefaultShortcuts; // shortcut sélectionné par défaut pour dateRef quand on change de type de graph
	zoom?: Shortcuts; // zoom à appliquer par défaut sur le graph
	maxdays?: number; // Nombre max de jours sélectionnables en fonction du type de graph
	refresh: string; // setTimeOut sur appel API pour refresh du graph (toutes les 10 minutes pour courbe de charge, tous les jours pour le reste)
	selectionStep?: string; // A prendre en compte plus tard ? Si énergie par semaine/mois/année, oblige à sélectionner une semaine/mois/année complet.ète
};

export const optionsMask: { [key in ElectricityTypes]: Options | undefined } = {
	[ElectricityTypes.Loadcurve]: {
		options: [
			Shortcuts.Today,
			Shortcuts.LastDay,
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
		],
		default: Shortcuts.LastDay,
		maxdays: 365,
		refresh: "each 10 minutes",
	},
	[ElectricityTypes.Power]: {
		options: [
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
		],
		default: Shortcuts.Last4Weeks,
		maxdays: 365,
		refresh: "each day",
	},
	[ElectricityTypes.TypicalDay]: {
		options: [
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
		], // Add seasons later? To add after RUST : Shortcuts.ThisYear, Shortcuts.LastYear, Shortcuts.Last12Months, Shortcuts.AllHistory
		default: Shortcuts.Last4Weeks, // consistency with Smart DIAG
		maxdays: 99, // To increase after RUST
		refresh: "each day",
	},
	// [ElectricityTypes.TypicalCustom]: {
	//   options: [Shortcuts.Yesterday, Shortcuts.ThisWeek, Shortcuts.LastWeek, Shortcuts.Last4Weeks, Shortcuts.ThisMonth, Shortcuts.LastMonth, Shortcuts.ThisQuarter, Shortcuts.LastQuarter], // Add seasons later? To add after RUST : Shortcuts.ThisYear, Shortcuts.LastYear, Shortcuts.Last12Months, Shortcuts.AllHistory
	//   default: Shortcuts.Last4Weeks, // consistency with Smart DIAG
	//   maxdays: 99, // To increase after RUST
	//   refresh: 'each day'
	// },
	[ElectricityTypes.CompareTypicalDay]: {
		options: [
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
		], // Add seasons later? To add after RUST : Shortcuts.ThisYear, Shortcuts.LastYear, Shortcuts.Last12Months
		compareOptions: [Shortcuts.SamePeriodLastYear, Shortcuts.PreviousPeriod],
		compareSameNumberOfDays: false,
		compareDefault: Shortcuts.SamePeriodLastYear,
		default: Shortcuts.Last4Weeks,
		maxdays: 99, // To increase after RUST
		refresh: "each day",
	},
	[ElectricityTypes.TypicalWeek]: {
		options: [
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
		], // Add seasons later? To add after RUST : Shortcuts.ThisYear, Shortcuts.LastYear, Shortcuts.Last12Months, Shortcuts.AllHistory
		default: Shortcuts.Last4Weeks,
		maxdays: 99, // To increase after RUST
		refresh: "each day",
	},
	[ElectricityTypes.CompareTypicalWeek]: {
		options: [
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
		], // Add seasons later? To add after RUST : Shortcuts.ThisYear, Shortcuts.LastYear, Shortcuts.Last12Months
		compareOptions: [Shortcuts.SamePeriodLastYear, Shortcuts.PreviousPeriod],
		compareSameNumberOfDays: false,
		compareDefault: Shortcuts.SamePeriodLastYear,
		default: Shortcuts.Last4Weeks,
		maxdays: 99, // To increase after RUST
		refresh: "each day",
	},
	[ElectricityTypes.EnergyDay]: {
		options: [
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
			Shortcuts.AllHistory,
		],
		default: Shortcuts.Last12Months,
		refresh: "each day",
	},
	[ElectricityTypes.EnergyWeek]: {
		options: [
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
			Shortcuts.AllHistory,
		],
		default: Shortcuts.AllHistory,
		selectionStep: "week",
		refresh: "each day",
	},
	[ElectricityTypes.EnergyMonth]: {
		options: [
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
			Shortcuts.AllHistory,
		],
		default: Shortcuts.AllHistory,
		selectionStep: "month",
		refresh: "each day",
	},
	[ElectricityTypes.EnergyYear]: {
		options: [Shortcuts.AllHistory],
		default: Shortcuts.AllHistory,
		selectionStep: "year",
		refresh: "each day",
	},
	[ElectricityTypes.PluriannualEvolution]: {
		options: [Shortcuts.AllHistory],
		default: Shortcuts.AllHistory,
		selectionStep: "year",
		refresh: "each day",
	},
	[ElectricityTypes.EnergyDonut]: {
		options: [
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
			Shortcuts.AllHistory,
		],
		default: Shortcuts.Last12Months,
		refresh: "each day",
	},
	[ElectricityTypes.CompareEnergy]: {
		options: [
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
		],
		compareOptions: [Shortcuts.SamePeriodLastYear, Shortcuts.PreviousPeriod],
		compareSameNumberOfDays: false,
		compareDefault: Shortcuts.SamePeriodLastYear,
		default: Shortcuts.Last4Weeks,
		refresh: "each day",
	},
	[ElectricityTypes.Benchmark]: {
		options: [
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
			Shortcuts.AllHistory,
		],
		default: Shortcuts.Last12Months,
		refresh: "each day",
	},
	[ElectricityTypes.QoE]: {
		options: [
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
		], // To add after RUST : Shortcuts.ThisYear, Shortcuts.LastYear, Shortcuts.Last12Months, Shortcuts.AllHistory
		default: Shortcuts.Last4Weeks,
		maxdays: 99,
		refresh: "each 10 minutes",
	},
	[ElectricityTypes.SubscribedPower]: {
		options: [
			Shortcuts.Yesterday,
			Shortcuts.ThisWeek,
			Shortcuts.LastWeek,
			Shortcuts.Last4Weeks,
			Shortcuts.ThisMonth,
			Shortcuts.LastMonth,
			Shortcuts.ThisQuarter,
			Shortcuts.LastQuarter,
			Shortcuts.ThisYear,
			Shortcuts.LastYear,
			Shortcuts.Last12Months,
			Shortcuts.AllHistory,
		], // Add seasons later?
		default: Shortcuts.Last4Weeks,
		refresh: "each 10 minutes",
	},
	[ElectricityTypes.KPI]: undefined,
};

// a function to return a translated string, not an object that would contain only english texts
export const shortcutLabel = (key: Shortcuts): string => {
	switch (key) {
		case Shortcuts.Today:
			return t`Today`;
		case Shortcuts.Yesterday:
			return t`Yesterday`;
		case Shortcuts.LastDay:
			return t`Last day`;
		case Shortcuts.ThisWeek:
			return t`This week`;
		case Shortcuts.LastWeek:
			return t`Last week`;
		case Shortcuts.Last4Weeks:
			return t`Last 4 weeks`;
		case Shortcuts.ThisMonth:
			return t`This month`;
		case Shortcuts.LastMonth:
			return t`Last month`;
		case Shortcuts.Last30Days:
			return t`Last 30 days`;
		case Shortcuts.ThisQuarter:
			return t`This quarter`;
		case Shortcuts.LastQuarter:
			return t`Last quarter`;
		case Shortcuts.ThisYear:
			return t`This year`;
		case Shortcuts.LastYear:
			return t`Last year`;
		case Shortcuts.Last12Months:
			return t`Last 12 months`;
		case Shortcuts.AllHistory:
			return t`All history`;
		case Shortcuts.SamePeriodLastYear:
			return t`Same period last year`;
		case Shortcuts.PreviousPeriod:
			return t`Previous period`;
	}
};

export const labelFromDates = (dateRange: DateRangeDate, currentLang: Lang) => {
	if (dateRange && currentLang) {
		const start = dateRange[0]
			? format(dateRange[0], "P", { locale: currentLang.dateLocale })
			: "";
		const end = dateRange[1]
			? format(dateRange[1], "P", { locale: currentLang.dateLocale })
			: "";
		return start === end ? t`On ${start}` : t`From ${start} to ${end}`;
	}
};

export const getShortcutFunctions = (
	state: Partial<MagicButtonState> &
		Pick<MagicButtonState, "site" | "minDate" | "maxDate" | "mainDates">,
): { [key in Shortcuts]: () => DateRangeDate } => {
	const fns = shortcutFunctions(state.site?.timezone);

	return {
		[Shortcuts.Today]: fns.getTodayInMonth,
		[Shortcuts.Yesterday]: fns.getYesterdayInMonth,
		[Shortcuts.LastDay]: () => fns.getLastDay([state.minDate, state.maxDate]),
		[Shortcuts.ThisWeek]: fns.getThisWeek,
		[Shortcuts.LastWeek]: fns.getLastWeek,
		[Shortcuts.Last4Weeks]: () =>
			fns.getFourLastWeeks([state.minDate, state.maxDate]),
		[Shortcuts.ThisMonth]: fns.getThisMonth,
		[Shortcuts.LastMonth]: fns.getLastMonth,
		[Shortcuts.Last30Days]: fns.getLast30days,
		[Shortcuts.ThisQuarter]: fns.getThisQuarter,
		[Shortcuts.LastQuarter]: fns.getLastQuarter,
		[Shortcuts.ThisYear]: fns.getThisYear,
		[Shortcuts.LastYear]: fns.getLastYear,
		[Shortcuts.Last12Months]: () =>
			fns.getLast12Months([state.minDate, state.maxDate]),
		[Shortcuts.AllHistory]: () => [state.minDate, state.maxDate],
		[Shortcuts.SamePeriodLastYear]: () =>
			fns.getSamePeriodLastYear(state.mainDates.dateRange),
		[Shortcuts.PreviousPeriod]: () =>
			fns.getPreviousPeriod(state.mainDates.dateRange),
	};
};

const buildLocation = (key: DateKeys, option: Shortcuts, location: Path) => {
	const parts: [string, string | undefined][] = [];
	switch (key) {
		case "mainDates":
			// payload = { mainDates: { dateRange: rng, shortcut: option } }
			parts.push(["period", ShortcutString[option]]);
			parts.push(["dateStart", undefined]);
			parts.push(["dateEnd", undefined]);
			break;
		case "compareDates":
			// payload = { compareDates: { dateRange: rng, shortcut: option } }
			parts.push(["comparePeriod", ShortcutString[option]]);
			parts.push(["compareDateStart", undefined]);
			parts.push(["compareDateEnd", undefined]);
			break;
	}

	return buildPath(location, parts);
};

export default function ShortCutMenu(props: ShorcutProps) {
	const { state } = useMagicButtonContext();
	const location = useLocation();
	const { setMobileOpen } = useDateRange();
	const { currentLang } = useLocales();
	const key = props.keyState;

	const shortcutFns = getShortcutFunctions(state);
	const handleClose = props.handleClose || (() => {});

	const ListItemTextSelected = styled(ListItemText)(({ theme }) => ({
		span: {
			...theme.typography.body2,
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightBold,
			// fontFamily: theme.typography.fontPR
		},
	}));

	const ListItemStyle = styled(ListItemText)(({ theme }) => ({
		span: {
			...theme.typography.body2,
		},
	}));

	const rangeLabel = labelFromDates(state[key].dateRange, currentLang);
	// console.log(state[key].shortcut, props.isMobile, 'mobile')
	const chartMask = optionsMask[state.chartType];
	const shortcutsOptions: Shortcuts[] = chartMask
		? key === "compareDates" && chartMask.compareOptions
			? chartMask.compareOptions
			: chartMask.options
		: [];

	return (
		<Scrollbar
			sx={
				props.isMobile
					? { height: 1, minWidth: 138 }
					: { height: 424, width: 138 }
			}
		>
			<List sx={{ bgcolor: "background.paper", minWidth: 138 }}>
				{shortcutsOptions.map((option) => {
					const checkShorcut = shortcutFns[option];
					const rng = checkShorcut ? checkShorcut() : null;
					const start =
						rng && rng[0]
							? format(rng[0], "P", { locale: currentLang.dateLocale })
							: "null";
					const end =
						rng && rng[1]
							? format(rng[1], "P", { locale: currentLang.dateLocale })
							: "null";

					// restrict to dates of installation
					const intersect = intersection(rng, [state.minDate, state.maxDate]);
					// console.log(intersect, 'mobile')

					if (intersect === null) {
						return;
						// return (
						//   <ListItemButton
						//     sx={{ padding: '8px 20px', cursor: 'none not-allowed' }}
						//     key={option}
						//     disabled={true}
						//     title={`${start} > ${end}`}
						//   >
						//     <ListItemStyle primary={shortcutLabel(option)} />
						//   </ListItemButton>
						// )
					} else {
						const to = buildLocation(key, option, location);

						return (
							<ListItemButton
								sx={{
									py: "8px",
									px: { xs: "10px", sm: "20px" },
									cursor: "pointer",
								}}
								key={option}
								selected={option === state[key].shortcut}
								onClick={() => {
									handleClose();
								}}
								component={RouterLink}
								to={to}
								title={`${start} > ${end}`}
							>
								{option === state[key].shortcut ? (
									<ListItemTextSelected primary={shortcutLabel(option)} />
								) : (
									<ListItemStyle primary={shortcutLabel(option)} />
								)}
							</ListItemButton>
						);
					}
				})}
				{props.isMobile ? (
					<ListItemButton
						sx={{ py: "8px", px: { xs: "10px", sm: "20px" } }}
						key={rangeLabel}
						selected={undefined === state[key].shortcut}
						onClick={(_event) => setMobileOpen(true)}
					>
						{undefined === state[key].shortcut ? (
							<ListItemTextSelected primary={rangeLabel} />
						) : (
							<ListItemStyle primary={rangeLabel} />
						)}
					</ListItemButton>
				) : null}
			</List>
		</Scrollbar>
	);
}
