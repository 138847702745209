import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// @mui
import {
	Box,
	IconButton,
	InputAdornment,
	LinearProgress,
	Stack,
	TextField,
	type TextFieldProps,
	Typography,
	useTheme,
} from "@mui/material";
import { isValid } from "date-fns";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
// form
import {
	Control,
	Controller,
	ControllerRenderProps,
	FieldErrors,
	FieldErrorsImpl,
	FieldValues,
	useFormContext,
} from "react-hook-form";
import { t } from "ttag";
import { PasswordStrengthMeter } from "../animate/PasswordStrengthMeter";
// ----------------------------------------------------------------------

interface IProps {
	name: string;
	requiredAsterix?: boolean;
	controlMethod?: Control<any, any>;
	isDisabled?: boolean;
	setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
	callbackPassword?: (field: ControllerRenderProps<any, string>) => void;
}

export default function RHFTextField({
	name,
	requiredAsterix,
	controlMethod,
	isDisabled,
	setIsDisabled,
	type,
	callbackPassword,
	...other
}: IProps & TextFieldProps) {
	const { control: controlForm } = useFormContext();
	const theme = useTheme();
	const control = controlMethod ? controlMethod : controlForm;
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prev: any) => !prev);
	};

	return (
		<Controller
			name={name}
			control={control}
			rules={{
				onChange: (e) => {
					if (callbackPassword) callbackPassword(e.target);
				},
			}}
			render={({ field, fieldState: { error } }) => {
				return (
					<Stack spacing={1} sx={{ width: 1 }}>
						<TextField
							{...field}
							value={field.value}
							disabled={isDisabled}
							fullWidth
							error={!!error || (requiredAsterix && field.value?.length === 0)}
							helperText={error?.message}
							required={requiredAsterix}
							type={type === "password" && !showPassword ? "password" : "text"}
							InputProps={
								type === "password"
									? {
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														edge="end"
														onClick={togglePasswordVisibility}
														aria-label={t`toggle password visibility`}
													>
														{showPassword ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}
									: undefined
							}
							sx={
								requiredAsterix
									? {
											"& .MuiOutlinedInput-root": {
												"& .MuiOutlinedInput-input.Mui-disabled": {
													cursor: isDisabled ? "not-allowed" : "auto",
												},
												"& .MuiOutlinedInput-notchedOutline": {
													borderWidth: 2,
													borderColor:
														!!error || field.value?.length === 0
															? theme.palette.error.main
															: theme.palette.primary.main,
												},
												"&:hover .MuiOutlinedInput-notchedOutline": {
													borderColor: theme.palette.primary.main,
												},
												"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
													borderColor: theme.palette.primary.main,
												},
											},
											"& .MuiFormLabel-root": {
												color: error
													? theme.palette.primary.main
													: theme.palette.primary.main,
												fontWeight: requiredAsterix ? 700 : 400,
												"&.Mui-error": {
													color: theme.palette.primary.main,
												},
											},
											"& .MuiFormLabel-asterisk": { color: "red" },
											width: 1,
										}
									: { width: 1 }
							}
							{...other}
						/>
					</Stack>
				);
			}}
		/>
	);
}
